import React, { useContext, useEffect, useState } from "react";
import { Modal, Spinner, Card } from "react-bootstrap";
import AuthContext from "../../store/auth-context";

import {
  GetPortalData,
  UpdatePortalServerCall,
  GetLogoFromServer,
  UploadLogoToServer,
  convertFileToImageString,
} from "../../services/PortalService";
import BrandColourSelect from "./BrandColourSelect";
import CompanyNameChange from "./CompanyNameChange";
import ImageUploader from "./ImageUploader";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

const EditBrandingComponent = (props) => {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [portal, setPortal] = useState(null);
  const [portalId, setPortalId] = useState("");
  const [usersPortalId, setUsersPortalId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [logoFile, setLogoFile] = useState();
  const [LogoFileOk, setLogoFileOk] = useState(false);
  const [menuBarDark, setMenuBarDark] = useState(true);
  const [primaryColour, setPrimaryColour] = useState("");
  const [primaryTextDark, setPrimaryColourTextDark] = useState(true);
  const [secondaryColour, setSecondaryColour] = useState("");
  const [secondaryTextDark, setSecondaryColourTextDark] = useState(true);
  const [buttonColour, setButtonColour] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [hasLogo, setHasLogo] = useState(false);
  const [logoImageString, setLogoImageString] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [logoUpdated, setLogoUpdated] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    if (props.portalId) {
      await refreshPortalData();
      setLoading(false);
    } else {
      setUserMessage("Failed To Get Portal Information");
      setLoading(false);
    }
  }, []);

  const refreshPortalData = async () => {
    const portal = await GetPortalData(props.portalId, authCtx.token);
    if (portal) {
      setPortal(portal);
      setPortalId(portal.id);
      setCompanyName(portal.name);
      setButtonColour(portal.buttonColour);
      setPrimaryColour(portal.primaryColour);
      setSecondaryColour(portal.secondaryColour);
      let menuDark = portal.menuBarColour === 0 ? true : false;
      setMenuBarDark(menuDark);
      let primaryDark = portal.primaryTextColour === 0 ? true : false;
      setPrimaryColourTextDark(primaryDark);
      let secondaryDark = portal.secondaryTextColour === 0 ? true : false;
      setSecondaryColourTextDark(secondaryDark);
      setUsersPortalId(props.usersPortalId);
      await updateLogoFromServer();
    }
  };

  const UpdateLogo = async (file) => {
    const newImageString = await convertFileToImageString(file);
    if (newImageString) {
      setLogoImageString(newImageString);
    }
  };

  const UpdatePortalBranding = (decodedToken) => {
    decodedToken.portal_primarycolour = primaryColour;
    decodedToken.portal_primarytextcolour = primaryTextDark ? "dark" : "light";
    decodedToken.portal_secondarycolour = secondaryColour;
    decodedToken.portal_secondarytextcolour = secondaryTextDark
      ? "dark"
      : "light";
    decodedToken.portal_menubarcolour = menuBarDark ? "dark" : "light";
    decodedToken.portal_name = companyName;

    localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
  };

  const updateLogoFromServer = async () => {
    var theState = this;
    const logoResponse = await GetLogoFromServer(props.portalId, authCtx.token);

    if ((await logoResponse.success) && logoResponse.logoFound) {
      setHasLogo(true);
      setLogoImageString(logoResponse.data);
    }
  };

  const updateCompanyName = (newValue) => {
    setCompanyName(newValue);
  };

  const UpdatePortal = async () => {
    setUserMessage("");
    setLoading(true);
    if (selectedFile) {
      if (selectedFile && !LogoFileOk) {
        setUserMessage("No Logo File");
        return;
      } else {
        await UploadLogoToServer(
          selectedFile,
          props.portalId,
          authCtx.token
        ).then((result) => {
          if (result.success) {
          } else {
            setUserMessage(result.errorMessage);
            setLoading(false);
            return;
          }
        });
      }
    }

    portal.name = companyName;
    portal.primaryColour = primaryColour;
    portal.secondaryColour = secondaryColour;
    portal.menuBarColour = menuBarDark ? 0 : 1;
    portal.primaryTextColour = primaryTextDark ? 0 : 1;
    portal.secondaryTextColour = secondaryTextDark ? 0 : 1;
    portal.buttonColour = buttonColour;

    UpdatePortalServerCall(portal, authCtx.token).then((result) => {
      if (result.success) {
        if (authCtx.decodedToken.portal_id === portal.id) {
          UpdatePortalBranding(authCtx.decodedToken);
        }
        setUserMessage(
          <div className="error-success">
            <BsFillCheckCircleFill className="success-icon" size={35} />
            <h5>Branding Changes Saved</h5>
            <p>Close this window to refresh</p>
          </div>
        );
      } else {
        setUserMessage(
          <div className="error-success">
            <BsFillXCircleFill className="failure-icon" size={35} />
            <h5>Error</h5>
            <p>Failed To Create Portal</p>
          </div>
        );
      }

      setLoading(false);
    });
  };

  const handleSubmit = () => {
    UpdatePortal();
    setOpen(true);
  };

  return (
    <>
      {loading && (
        // Use our custom loading state to show a loading indicator
        <div className="text-center">
          <div className="loading">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            <span>Loading...</span>
          </div>
        </div>
      )}

      {!loading && (
        <>
          <Card className="edit-branding">
            <CompanyNameChange
              companyName={companyName}
              updateCompanyName={updateCompanyName}
            />
            <h4>Theme</h4>
            <ImageUploader
              setLogoFile={setLogoFile}
              setLogoImageString={setLogoImageString}
              setLogoFileOk={setLogoFileOk}
              portalId={portalId}
              updateSelectedFile={setSelectedFile}
              UpdateLogo={UpdateLogo}
            />

            <BrandColourSelect
              portal={portal}
              portalName={props.portalName}
              logo={logoFile}
              logoImageString={logoImageString}
              updateMenuBarDark={setMenuBarDark}
              updatePrimaryColour={setPrimaryColour}
              updatePrimaryTextDark={setPrimaryColourTextDark}
              updateSecondaryColour={setSecondaryColour}
              updateSecondarytextDark={setSecondaryColourTextDark}
              hasLogo={hasLogo}
            />
          </Card>
          <div className="my-5 text-center">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Save Changes
            </button>
          </div>
        </>
      )}
      {!loading && userMessage !== null && (
        <Modal show={open}>
          <Modal.Header>
            <Modal.Title>Edit Branding</Modal.Title>
          </Modal.Header>
          <Modal.Body>{userMessage}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpen(false);
                window.location.reload(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EditBrandingComponent;
