import { Fragment, useState, useRef, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import UserAdminTable from "./UserAdminTable";

const UserAdmin = () => {
  let history = useHistory();
  const location = useLocation();
  let { portalId } = useParams();
  const [portalName, setPortalName] = useState();
  const [usersId] = useState("");
  const [usersPortalId] = useState("");

  useEffect(() => {
    setPortalName(location.state.portalName);
  }, [location.state.portalName]);

  const backToDashboard = () => {
    history.push({
      pathname: "/adminDashboard",
      state: {
        portalId: portalId,
        portalName: portalName,
        userId: usersId, // needed to come back to page
        usersPortalId: usersPortalId,
      },
    });
  };

  const NavigateNewUser = () => {
    history.push({
      pathname: "/newUser/" + portalId,
      state: {
        portalId: portalId,
        portalName: portalName,
        userId: usersId, // needed to come back to page
        usersPortalId: usersPortalId,
      },
    });
  };

  const pageSizeUpdateHandler = (pageSize) => {
    setPageSize(pageSize);
  };

  const refresh = useRef(null);
  const clearSelection = useRef(null);
  const refreshHandler = () => {
    refresh.current({ pageSize: pageSize, pageIndex: 0 });
    clearSelection.current();
  };
  const [pageSize, setPageSize] = useState({});

  return (
    <div id="user-admin">
      <Toolbar>
        <h2>{portalName}</h2>
        <ToolbarButton
          label="Dashboard"
          onClick={backToDashboard}
          action="back"
        ></ToolbarButton>
      </Toolbar>
      <Container>
        <div className="text-center mt-5">
          <h3 className="font-weight-semibold">User Administration</h3>
        </div>
        <Row className="justify-content-md-center mt-5">
          <Col xs ls="6">
            {portalName && (
              <Fragment>
                <UserAdminTable
                  onPageSizeUpdate={pageSizeUpdateHandler}
                  refresh={refreshHandler}
                  pageSize={pageSize}
                  clearSelection={clearSelection}
                  history={history}
                  portalId={portalId}
                  portalName={portalName}
                />
              </Fragment>
            )}
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-4">
          <Col className="text-center">
            <button className="btn btn-primary" onClick={NavigateNewUser}>
              Create User
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserAdmin;
