import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import { Card, Container, Row, Col } from "react-bootstrap";
import AuthContext from "../../store/auth-context";
import { ReactComponent as PaintBrushIcon } from "../../images/icons/admin-paintbrush.svg";
import { ReactComponent as SimCardIcon } from "../../images/icons/admin-edit-sims.svg";
import { ReactComponent as UserIcon } from "../../images/icons/admin-users.svg";

const AdminDashboard = (props) => {
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  let history = useHistory();
  const [portalId, setPortalId] = useState("");
  const [portalName, setPortalName] = useState("");
  const [hasParentPortal, setHasParentPortal] = useState(true);

  useEffect(() => {
    setPortalId(location.state.portalId);
    setPortalName(location.state.portalName);
    setHasParentPortal(location.state.hasParentPortal);
  }, [location]);

  const BackToAccountList = () => {
    history.push("/portalList/" + authCtx.decodedToken.id);
  };

  const GoToEditBranding = () => {
    history.push({
      pathname: "/editBranding",
      state: { portalId: portalId, portalName: portalName },
    });
  };

  const GoToSimList = () => {
    history.push({
      pathname: "/portalSims/" + portalId,
      state: { portalId: portalId, portalName: portalName },
    });
  };

  const GoToUserList = () => {
    history.push({
      pathname: "/userAdmin/" + portalId,
      state: { portalId: portalId, portalName: portalName },
    });
  };

  return (
    <>
      <Toolbar>
        <h2>{portalName}</h2>
        <ToolbarButton
          label="Account List"
          action="backToAccounts"
          onClick={BackToAccountList}
        ></ToolbarButton>
      </Toolbar>
      <Container className="admin-dashboard">
        <div className="text-center mt-5">
          <h3 className="text-primary">Admin Dashboard</h3>
        </div>
        <Row className="justify-content-center ">
          <Col sm="auto" className="justify-content-center d-flex">
            <Card onClick={GoToEditBranding} className="admin-card">
              <Card.Body>
                <PaintBrushIcon></PaintBrushIcon>
                <span>Edit Branding</span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="auto" className="justify-content-center d-flex">
            <Card onClick={GoToSimList} className="admin-card">
              <Card.Body>
                <SimCardIcon></SimCardIcon>
                <span>Edit SIM List</span>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="auto" className="justify-content-center d-flex">
            <Card onClick={GoToUserList} className="admin-card">
              <Card.Body>
                <UserIcon></UserIcon>
                <span>Users List</span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminDashboard;
