import { useState, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { Form, Spinner, Container, Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams, useLocation } from "react-router-dom";
import {
  GetUser,
  GetAllPermissions,
  SaveUserDetails,
} from "../../services/UserServices";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import { useHistory } from "react-router-dom";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import EditUserConfirmationPopup from "./EditUserConfirmationPopup";

const EditUser = (props) => {
  let history = useHistory();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [emailAddress, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [portalName, setPortalName] = useState("");
  const [portalId, setPortalId] = useState("");
  const [userId, setUserId] = useState("");
  const [userPermissions, setUserPermissions] = useState([]);
  const [userPermissionDisplay, setUserPermissionDisplay] = useState([]);
  const [userPermissionIds, setPermissionIds] = useState([]);
  const [messageDisplayed, setMessageDisplayed] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  let { id } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDisplayMessage("");
  };

  useEffect(() => {
    setPortalName(location.state.portalName);
    setPortalId(location.state.portalId);
  }, [location]);

  const CreateUserDisplayPermission = (permissions, userPermissionsA) => {
    let displayPermissions = [];
    let userPermission = [];
    permissions.forEach((element) => {
      var userFound = userPermissionsA.find((x) => x === element.id);
      var userHasPermission = userFound ? true : false;
      let field = {
        permissionId: element.id,
        name: element.name.replace(/([A-Z])/g, " $1").trim(),
        hasPermission: userHasPermission,
      };
      if (userHasPermission) {
        userPermission.push(element.id);
      }
      displayPermissions.push(field);
    });
    setUserPermissionDisplay(displayPermissions);
    setPermissionIds(userPermission);
  };

  const GetAddPermissions = (selectedPermissions) => {
    let permissionsToAdd = selectedPermissions.filter((permission) => {
      return userPermissionIds.indexOf(permission) === -1;
    });

    return permissionsToAdd;
  };

  const GetDeletePermissions = (selectedPermissions) => {
    let permissionsToRemove = userPermissionIds.filter((permission) => {
      return selectedPermissions.indexOf(permission) === -1;
    });

    return permissionsToRemove;
  };

  const backToUserAdmin = () => {
    history.push({
      pathname: "/userAdmin/" + portalId,
      state: { portalId: portalId, portalName: portalName },
    });
  };

  useEffect(() => {
    GetUser(id, authCtx.token).then((result) => {
      if (!result.error) {
        setEmail(result.emailAddress);
        setFirstName(result.firstName);
        setLastName(result.lastName);
        setUserId(result.id);
        const objectArray = [];
        result.permissionIds.forEach((permission) => {
          objectArray.push(permission);
        });
        setUserPermissions(objectArray);

        GetAllPermissions(authCtx.token).then((response) => {
          if (!response.error) {
            CreateUserDisplayPermission(response.permissions, objectArray);
          }

          setLoading(false);
        });
      }
    });
  }, [authCtx.token, id]);

  const userEditValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Enter user's first name")
      .max(50, "First name can not be over 50 characters"),
    lastName: Yup.string()
      .required("Enter user's first name")
      .max(50, "Second name can not be over 50 characters"),
  });

  return (
    <div>
      <Toolbar>
        <h2>{portalName}</h2>
        <ToolbarButton
          label="User Admin"
          onClick={backToUserAdmin}
          action="back"
        ></ToolbarButton>
      </Toolbar>
      <Container>
        <Row>
          <Col>
            {loading && (
              // Use our custom loading state to show a loading indicator
              <div className="text-center my-5">
                <div className="loading">
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  <span>Loading...</span>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs ls="6">
            {!loading && (
              <div>
                <Formik
                  initialValues={{
                    firstName: firstName,
                    lastName: lastName,
                    emailaddress: emailAddress,
                    password: "",
                    userId: userId,
                    recordPermissions: userPermissionIds,
                  }}
                  enableReinitialize={true}
                  validationSchema={userEditValidationSchema}
                  onSubmit={(values) => {
                    values.permissionsToAdd = GetAddPermissions(
                      values.recordPermissions
                    );
                    values.permissionsToRemove = GetDeletePermissions(
                      values.recordPermissions
                    );
                    SaveUserDetails(values, authCtx.token).then((result) => {
                      if (result.success) {
                        setDisplayMessage(
                          <div className="error-success">
                            <BsFillCheckCircleFill
                              className="success-icon"
                              size={35}
                            />
                            <h5>User updated</h5>
                          </div>
                        );
                        setMessageDisplayed(true);
                      } else {
                        setDisplayMessage(
                          <div className="error-success">
                            <BsFillXCircleFill
                              className="failure-icon"
                              size={35}
                            />
                            <h5>Error updating user</h5>
                          </div>
                        );
                        setMessageDisplayed(true);
                      }
                    });
                    setShow(true);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      handleBlur,
                    } = props;
                    return (
                      <div>
                        <div className="text-center mt-5">
                          <h3 className="font-weight-semibold">
                            Edit User: {firstName} {lastName}
                          </h3>
                        </div>
                        <Row>
                          <Col
                            xs={{ span: 12, offset: 0 }}
                            lg={{ span: 8, offset: 2 }}
                          >
                            <Form onSubmit={handleSubmit}>
                              <Card className="my-4 p-5">
                                <Form.Group className="form-group">
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    name="emailaddress"
                                    type="text"
                                    value={emailAddress}
                                    readOnly
                                  ></Form.Control>
                                  <Form.Control
                                    type="text"
                                    value={values.userId}
                                    name="userId"
                                    readOnly
                                    hidden
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group className="form-group">
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control
                                    name="firstName"
                                    type="text"
                                    placeholder={values.firstName}
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors.firstName &&
                                      touched.firstName &&
                                      "error"
                                    }
                                  ></Form.Control>
                                </Form.Group>
                                {errors.firstName && touched.firstName ? (
                                  <div className="input-feedback">
                                    {errors.firstName}
                                  </div>
                                ) : null}
                                <Form.Group className="form-group">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    name="lastName"
                                    type="text"
                                    placeholder={values.lastName}
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      errors.lastName &&
                                      touched.lastName &&
                                      "error"
                                    }
                                  ></Form.Control>
                                </Form.Group>
                                {errors.lastName && touched.lastName ? (
                                  <div className="input-feedback">
                                    {errors.lastName}
                                  </div>
                                ) : null}
                                <Form.Group className="form-group permissions">
                                  <h5>Permissions</h5>
                                  {userPermissionDisplay.map((permission) => {
                                    if (permission.name !== "View Sims") {
                                      return (
                                        <Form.Check
                                          key={permission.permissionId}
                                          label={permission.name}
                                          type="checkbox"
                                          value={permission.permissionId}
                                          name="recordPermissions"
                                          checked={values.recordPermissions.includes(
                                            permission.permissionId
                                          )}
                                          onChange={handleChange}
                                        ></Form.Check>
                                      );
                                    } else {
                                      return <div></div>;
                                    }
                                  })}
                                </Form.Group>
                              </Card>
                              <div className="text-center my-5">
                                <button
                                  type="submit"
                                  className="btn btn-primary p-7 m-7"
                                >
                                  Update User
                                </button>
                              </div>
                            </Form>
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Formik>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <EditUserConfirmationPopup
        show={show}
        handleClose={handleClose}
        setText={displayMessage}
        backToUserAdmin={backToUserAdmin}
      />
    </div>
  );
};

export default EditUser;
