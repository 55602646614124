import { useState,useContext } from "react";
import config from "../../store/app.config";
import AuthContext from "../../store/auth-context";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

const ResetForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [noticeMessage, setNoticeMessage] = useState("");
  const authCtx = useContext(AuthContext);

  return (
    <Formik
    
      initialValues={{ email: "" }}
      onSubmit={(values) => {
        setIsLoading(true);
        const submitObject ={
          "EmailAddress": authCtx.decodedToken.email,
        }
        fetch(`${config.API_BASE_URL}api/reset`, {
          method: "POST",
          body: JSON.stringify(submitObject),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            setIsLoading(false);
            if (response.ok) {
              
              setNoticeMessage(
                "Reset Password email sent, please check your inbox. May take up to 10 mins to come through"
              );
            }
            if (response.status === 400) {
              
              throw Error(response.status);
            }
            if (response.status === 404) {
              
              setNoticeMessage("No account with specified email found.");
            }
            if (response.status === 500) {
              
              throw Error(response.status);
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(
              "Error when emailing password reset instructions. If this" +
                " is the first time you've seen this, please try again. " +
                "Otherwise, please contact support."
            );
          });
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="p-4">
                    <div className="text-center mt-2">
                      <h2 className="text-primary">Reset your password</h2>
                      <p className="text-muted">
                        Please enter your email address.
                      </p>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleSubmit}>
                        {errorMessage && (
                          <Alert variant="danger">{errorMessage}</Alert>
                        )}
                        {noticeMessage && (
                          <Alert variant="warning">{noticeMessage}</Alert>
                        )}
                        {errors.email && touched.email && (
                          <div className="input-feedback">{errors.email}</div>
                        )}
                        <div className="d-grid gap-2">
                          {!isLoading && (
                            <Button
                              variant="primary"
                              type="submit"
                              tabIndex={2}
                            >
                              Reset Password
                            </Button>
                          )}
                          {isLoading && (
                            <Button variant="primary" disabled tabIndex={2}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Sending request...
                            </Button>
                          )}
                        </div>
                      </Form>
                      <div className="mt-3 text-center">
                        <Link to="/auth">Return to sign in</Link>
                      </div>
                      <div className="p-2 mt-4"></div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    </Formik>
  );
};

export default ResetForm;
