import PortalList from "../components/Admin/PortalList";
import PortalUpdate from "../components/Admin/PortalUpdate";
import PortalPage from "../components/PortalList/PortalPage";

// we should do a check here, if they have access to multiple portals OR if they can create portals then show the tree view, otherwise just show their current portal.
const AdminPage = () => {
  return <PortalPage />;
};

export default AdminPage;
