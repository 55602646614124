import config from "../store/app.config";

const baseUrl = config.API_BASE_URL;

export const DeleteUserOnServer = async (userId, token) => {
  try {
    const response = await fetch(`${baseUrl}api/user/${userId}`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    if (await response.ok) {
      return { success: true };
    } else {
      return { success: false, data: response.statusText };
    }
  } catch (error) {
    return { success: false, data: error };
  }
};

export const GetUser = async (userId, token) => {
  try {
    const response = await fetch(`${baseUrl}api/user/${userId}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });
    if (await response.ok) {
      return await response.json();
    } else {
      return { error: response.statusText };
    }
  } catch (error) {
    return { error: error };
  }
};

export const GetAllPermissions = async (token) => {
  try {
    const response = await fetch(`${baseUrl}api/user/permissions`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    return await response.json();
  } catch (Error) {
    return { error: Error };
  }
};

export const SaveUserDetails = async (values, token) => {
  try {
    let permissionsUpdated = true;
    try {
      for (const permissionId of values.permissionsToAdd) {
        const permissionResponse = await fetch(
          `${baseUrl}api/user/${values.userId}/permission/${permissionId}`,
          {
            method: "PUT",
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }),
          }
        );

        if ((await permissionResponse.ok) === false) {
          permissionsUpdated = false;
        }
      }

      for (const permissionId of values.permissionsToRemove) {
        const permissionResponse = await fetch(
          `${baseUrl}api/user/${values.userId}/permission/${permissionId}`,
          {
            method: "DELETE",
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }),
          }
        );

        if ((await permissionResponse.ok) === false) {
          permissionsUpdated = false;
        }
      }
    } catch (error) {
      permissionsUpdated = false;
    }

    if (!permissionsUpdated) {
      return { success: false };
    }

    const userBody = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    const userResponse = await fetch(`${baseUrl}api/user/${values.userId}/`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(userBody),
    });

    if (permissionsUpdated && (await userResponse.ok)) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export const CreateNewUser = async (values, token) => {
  try {
    const createUserBody = {
      emailAddress: values.emailAddress,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      portalId: values.portalId,
      permissionIds: values.recordPermissions,
    };

    const userCreateResponse = await fetch(`${baseUrl}api/user/`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(createUserBody),
    });

    if (await userCreateResponse.ok) {
      return {
        success: true,
        failureMessage: "",
      };
    } else {
      if (userCreateResponse.status === 409) {
        return {
          success: false,
          failureMessage: "User already exists",
        };
      }
      return {
        success: false,
        failureMessage: "Failed To Create User ",
      };
    }
  } catch (error) {
    return {
      success: false,
      failureMessage: error,
    };
  }
};

export const GetUsersByPortal = async (portalId, request, token) => {
  try {
    const response = await fetch(`${baseUrl}api/user/${portalId}/portal`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        tokenPortalId: portalId,
      }),
      body: JSON.stringify(request),
    });
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return await response.json();
  } catch (error) {
    return { error: true };
  }
};
