import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";

import AuthContext from "../../store/auth-context";
import MainTable from "../UI/MainTable/MainTable";
import {
  DeleteUserOnServer,
  GetUsersByPortal,
} from "../../services/UserServices";
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

import "./UserAdmin.scss";

function UserAdminTable(props) {
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [useSelectColumn] = React.useState(false);
  const authCtx = useContext(AuthContext);
  const [useSearch] = React.useState(false);
  const [searchTerm] = useState("");
  const [seletectedUser, setSelectedUser] = React.useState("");
  const [pagePerLabel] = React.useState("Users");
  const [deletionSuccessful, setDeletionSuccessful] = useState(false);

  const defaultPopupText = "Are you sure you want to delete user?";
  const [popUpText, setPopUpText] = React.useState(defaultPopupText);

  const refresh = useRef(null);

  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
    setPopUpText(defaultPopupText);
    setDeletionSuccessful(false);
  };

  // Passing the request to clear selection to MainTable
  const clearSelection = useRef(null);
  const clearSelectionHandler = () => {
    clearSelection.current();
  };

  // Removing empty columns from decoded token using spread syntax to build up list which is passed via props.
  let hiddenCols = [];
  if (authCtx.decodedToken.portal_tariffvisible === "False") {
    hiddenCols = [...hiddenCols, "tariffName"];
  }
  if (authCtx.decodedToken.portal_billingvisible === "False") {
    hiddenCols = [...hiddenCols, "billing"];
  }

  const OpenConfirmation = (userId) => {
    setSelectedUser(userId);
    setshow(true);
  };

  const DeleteUser = () => {
    let user = seletectedUser;
    if (user.value === authCtx.decodedToken.id) {
      setPopUpText("Cannot delete own user");
      return;
    }
    DeleteUserOnServer(user.value, authCtx.token).then((result) => {
      if (result.success) {
        setPopUpText(
          <div className="error-success">
            <BsFillCheckCircleFill className="success-icon" size={35} />
            <h5>User deleted</h5>
          </div>
        );
        setDeletionSuccessful(true);
        setTimeout(() => {
          clearSelectionHandler();
          handleClose();
          //props.refresh();
        }, 3000);
      } else {
        setPopUpText(
          <div className="error-success">
            <BsFillXCircleFill className="failure-icon" size={35} />
            <h5>Error deleting user</h5>
            <p>{result.data}</p>
          </div>
        );
      }
    });
  };

  const EditUser = (userId) => {
    props.history.push({
      pathname: "/editUser/" + userId.value,
      state: { portalId: props.portalId, portalName: props.portalName },
    });
  };

  // Defining columns for main table
  const columns = React.useMemo(
    () => [
      {
        Header: "Email Address",
        accessor: "emailAddress",
        disableSortBy: true,
      },
      { Header: "First Name", accessor: "firstName", disableSortBy: false },
      { Header: "Last Name", accessor: "lastName", disableSortBy: false },
      {
        Header: "Edit",
        id: "buttonEdit",
        accessor: "id",
        Cell: ({ value }) => (
          <button
            className="btn btn-link edit"
            onClick={() => {
              EditUser({ value });
            }}
          >
            <FaUserEdit /> Edit
          </button>
        ),
      },
      {
        Header: "Delete",
        id: "buttonDelete",
        accessor: "id",
        Cell: ({ value }) => (
          <button
            className="btn btn-link delete"
            onClick={() => OpenConfirmation({ value })}
          >
            <FaUserTimes color="#dc3545" /> Delete
          </button>
        ),
      },
    ],
    [EditUser]
  );

  const fetchAPIData = async ({ pageSize, pageIndex }) => {
    try {
      setLoading(true);
      var request = {
        pageNumber: pageIndex,
        pageSize: pageSize,
        portalId: props.portalId,
      };

      const data = await GetUsersByPortal(
        props.portalId,
        request,
        authCtx.token
      );
      if (!data.error) {
        setData(data.data);
        setPageCount(data.totalPages);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy, searchTerm, searchField }) => {
      fetchAPIData({
        pageSize: pageSize,
        pageIndex: pageIndex + 1,
        searchTerm: searchTerm,
        searchField: searchField,
        sortBy: sortBy,
      });
    },
    [searchTerm]
  );

  // Refresh function, passed through props
  useEffect(() => {
    props.refresh.current = fetchData;
    props.clearSelection.current = clearSelectionHandler;
  }, [props.clearSelection, props.refresh]);

  // Receive selected SIM list from Table, pass it up to SimControl
  const selectListUpdateHandler = (selectedRowIds) => {};

  // Receive page size from Table, pass it up to SimControl
  const pageSizeUpdateHandler = (pageSize) => {
    props.onPageSizeUpdate(pageSize);
  };

  return (
    <div className="no-checkboxes user-table">
      <MainTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        onSelectListUpdate={selectListUpdateHandler}
        onPageSizeUpdate={pageSizeUpdateHandler}
        clearSelection={clearSelection}
        hiddenCols={hiddenCols}
        getCellProps={(cellInfo) => ({
          className:
            (cellInfo.value === "–" || cellInfo.value === null) && "grey",
        })}
        getRowId={(row) => row.id}
        enableSelection={useSelectColumn}
        enableSearch={useSearch}
        perPageLabel={pagePerLabel}
      />
      <DeleteConfirmationPopup
        show={show}
        handleClose={handleClose}
        handleOk={DeleteUser}
        setText={popUpText}
        deletionSuccessful={deletionSuccessful}
      />
    </div>
  );
}

export default UserAdminTable;
