import React, { Fragment, useContext } from "react";
import AuthContext from "../../store/auth-context";
import { Link } from "react-router-dom";

import { NavDropdown } from "react-bootstrap";
import { HiLogout } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { HiUserCircle } from "react-icons/hi";

import "./ProfileMenu.scss";
function ProfileMenu(props) {
  const authCtx = useContext(AuthContext);

  const logoutHandler = () => {
    authCtx.logout();
  };

  const urlNav = "portalList/" + authCtx.decodedToken.id;
  const resetUrl = "passwordReset"
  const isAdmin = authCtx.decodedToken.scope.includes('Admin'); 
 

  return (
    <Fragment>
      <NavDropdown
        className="user"
        title={
          <div style={{ display: "inline-block" }} className="username">
            <span>
              <HiUserCircle className="icon" />
              {authCtx.decodedToken.firstname} {authCtx.decodedToken.lastname}
            </span>
          </div>
        }
        align="end"
      >  
       <NavDropdown.Item as={Link} to={resetUrl}>
        <FiSettings /> Change Password
      </NavDropdown.Item>
      {isAdmin && (
        <NavDropdown.Item as={Link} to={urlNav}>
        <FiSettings /> Admin
      </NavDropdown.Item>
      )}
         
          <NavDropdown.Item onClick={logoutHandler}>
              <HiLogout /> Log out
              </NavDropdown.Item>
      </NavDropdown>
    </Fragment>
  );
}

export default ProfileMenu;
