import { Modal, Form } from "react-bootstrap";

const PortalDetailsModal = (props)=> {

    const handleSubmit = (e)=>{
        e.preventDefault(); 
        let showTariff = e.target.showTariff.checked; 
        let showBilling = e.target.showBilling.checked; 
        let exportVisible  = e.target.allowExport.checked; 
        let newPortalName = e.target.name.value
       
        
        props.CreatePortal(newPortalName,showTariff, showBilling, exportVisible )
    }


    return (
        <Modal show={props.show}>
          <Modal.Header>
            <Modal.Title>New Portal Details</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
          <Modal.Body>
            
                <Form.Group>
                    <Form.Label>
                        Portal Name
                    </Form.Label>
                    <Form.Control type="text" name="name" placeholder="Name" autoFocus required/>
                </Form.Group>
                <Form.Group>
                    <Form.Check type="checkbox" name="showTariff" label="Show Tariff" />
                </Form.Group>
                <Form.Group>
                    <Form.Check type="checkbox" name="showBilling" label="Show Billing" />
                </Form.Group>
                <Form.Group>
                    <Form.Check type="checkbox"  name="allowExport" label="Allow Export" />
                </Form.Group>
          
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={props.handleClose}>
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Create
            </button>
          </Modal.Footer>
          </Form>
        </Modal>
      );
}

export default PortalDetailsModal;