import React, { useState, Fragment, useContext } from "react";
import { Button } from "react-bootstrap";
import { HiPlus, HiOutlinePencil, HiTrash } from "react-icons/hi";
import SimLabelForm from "./SimLabelForm";
import { DeleteSimLabel, EditSimLabel, CreateSimLabel } from "../../../services/SimService";
import AuthContext from "../../../store/auth-context";
import "./SimLabel.scss";

export const SimLabel = (props) => {
  const [fade, setFade] = useState(true);
  const [label, setLabel] = useState(props.textLabel ?? "");
  const authCtx = useContext(AuthContext);

  const HandleDelete = (serialNumber) => {
    var response = DeleteSimLabel(serialNumber, authCtx.token, authCtx.decodedToken.portal_id);
    if (response.success == false) {
      return;
    }
    ChangeButtonState("", true);
  };

  const HandleCreate = (serialNumber, textLabel) => {
    var response = CreateSimLabel(serialNumber, textLabel, authCtx.token, authCtx.decodedToken.portal_id);
    if (response.success == false) {
      return;
    }
    ChangeButtonState(textLabel, true);
  };

  const HandleEdit = (serialNumber, textLabel) => {
    var response = EditSimLabel(serialNumber, textLabel, authCtx.token, authCtx.decodedToken.portal_id);
    if (response.success == false) {
      return;
    }
    ChangeButtonState(textLabel, true);
  };

  const ChangeButtonState = (labelValue, fade) => {
    setLabel(labelValue);
    setFade(fade);
  };

  return label === "" ? (
    <Fragment>
      {fade ? (
        <Button className="add-label" variant="link" onClick={() => setFade(false)}>
          <HiPlus /> Add New Label
        </Button>
      ) : (
        <SimLabelForm
          labelValue={label}
          setLabel={setLabel}
          serialNumber={props.serialNumber}
          fade={fade}
          Cancel={ChangeButtonState}
          handleSubmit={HandleCreate}
        />
      )}
    </Fragment>
  ) : (
    <Fragment>
      {fade ? (
        <div className="label-container">
          <span>{label}</span>
          <div className="button-container">
            <HiOutlinePencil className="info" role="button" onClick={() => ChangeButtonState(label, false)} />
            <HiTrash className="danger" role="button" onClick={() => HandleDelete(props.serialNumber)} />
          </div>
        </div>
      ) : (
        <div className="label-form">
          <SimLabelForm
            labelValue={label}
            setLabel={setLabel}
            serialNumber={props.serialNumber}
            fade={fade}
            Cancel={ChangeButtonState}
            handleSubmit={HandleEdit}
          />
        </div>
      )}
    </Fragment>
  );
};
