import { Form, Button, Spinner } from "react-bootstrap";

import { Formik } from "formik";
import * as Yup from "yup";

const ProfileForm = () => (
  <Formik
    initialValues={{
      password: "",
      changepassword: "",
    }}
    onSubmit={(values, { setSubmitting }) => {
      console.log("Submitting...");
    }}
    validationSchema={Yup.object().shape({
      password: Yup.string()
        .required("Enter new password.")
        .min(8, "Password too short. Must be at least 8 characters long."),
      changepassword: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same."
        ),
      }),
    })}
  >
    {(props) => {
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
      } = props;
      return (
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Enter new password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.password && touched.password && "error"}
              />
            </Form.Group>
            {errors.password && touched.password && (
              <div className="input-feedback">{errors.password}</div>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Confirm new password</Form.Label>
              <Form.Control
                name="changepassword"
                type="password"
                value={values.changepassword}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.changepassword && touched.changepassword && "error"
                }
              />
            </Form.Group>

            {errors.changepassword && touched.changepassword && (
              <div className="input-feedback">{errors.changepassword}</div>
            )}
            <div className="d-grid gap-2">
              {!isSubmitting && (
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
              )}
              {isSubmitting && (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Sending request...
                </Button>
              )}
            </div>
          </Form>
        </div>
      );
    }}
  </Formik>
);

export default ProfileForm;
