import { Fragment, useState, useEffect, useContext } from "react";
import { Form, Spinner, Container, Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import { useHistory, useParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { GetAllPermissions, CreateNewUser } from "../../services/UserServices";
import CreateUserConfirmationPopup from "./CreateUserConfirmationPopup";
import AuthContext from "../../store/auth-context";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";

const CreateUser = (props) => {
  let history = useHistory();
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [userPemissionIds] = useState([]);
  const [userPermissionDisplay, setUserPermissionDisplay] = useState([]);
  let { portalId } = useParams();
  const [popUpText, setPopUpText] = useState("");
  const [show, setshow] = useState(false);
  const handleClose = () => {
    setshow(false);
    setPopUpText("");
  };
  const [portalName, setPortalName] = useState("");
  const [usersId, setUsersId] = useState("");
  const [usersPortalId, setUsersPortalId] = useState("");

  useEffect(() => {
    setPortalName(location.state.portalName);
    setUsersId(location.state.userId);
    setUsersPortalId(location.state.usersPortalId);
  }, [location]);

  const backToUserAdmin = () => {
    history.push({
      pathname: "/userAdmin/" + portalId,
      state: {
        portalId: portalId,
        portalName: portalName,
        userId: usersId,
        usersPortalId: usersPortalId,
      },
    });
  };

  const CreateUserDisplayPermission = (permissions) => {
    let displayPermissions = [];
    permissions.forEach((element) => {
      let field = {
        permissionId: element.id,
        name: element.name.replace(/([A-Z])/g, " $1").trim(),
      };
      displayPermissions.push(field);
    });
    setUserPermissionDisplay(displayPermissions);
  };

  useEffect(() => {
    GetAllPermissions(authCtx.token).then((response) => {
      if (!response.error) {
        CreateUserDisplayPermission(response.permissions);
      }
      setLoading(false);
    });
  }, [authCtx.token]);

  const NewUserValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Enter user's first name")
      .max(50, "First name can not be over 50 characters"),
    lastName: Yup.string()
      .required("Enter user's last name")
      .max(50, "First name can not be over 50 characters"),
    emailAddress: Yup.string()
      .required("Enter user's email address")
      .email("Enter a valid email address"),
    password: Yup.string()
      .required("Enter a password")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string().test(
      "password-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  });

  return (
    <div>
      <Toolbar>
        <h2>Create User</h2>
        <ToolbarButton
          label="User Admin"
          onClick={backToUserAdmin}
          action="back"
        ></ToolbarButton>
      </Toolbar>
      <Container>
        <Fragment>
          {loading && (
            // Use our custom loading state to show a loading indicator
            <div className="text-center">
              <div className="loading">
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                <span>Loading...</span>
              </div>
            </div>
          )}
          {!loading && (
            <div>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  emailAddress: "",
                  password: "",
                  confirmPassword: "",
                  recordPermissions: userPemissionIds,
                  portalId: portalId,
                }}
                validationSchema={NewUserValidationSchema}
                onSubmit={(values) => {
                  setLoading(true);
                  CreateNewUser(values, authCtx.token).then((result) => {
                    setLoading(false);
                    if (result.success) {
                      setPopUpText(
                        <div className="error-success">
                          <BsFillCheckCircleFill
                            className="success-icon"
                            size={35}
                          />
                          <h5>User created</h5>
                        </div>
                      );
                    } else {
                      setPopUpText(
                        <div className="error-success">
                          <BsFillXCircleFill
                            className="failure-icon"
                            size={35}
                          />
                          <h5>Error creating user</h5>
                          <p>{result.failureMessage}</p>
                        </div>
                      );
                    }

                    setshow(true);
                  });
                }}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                  } = props;
                  return (
                    <div>
                      <div className="text-center mt-5">
                        <h3 className="font-weight-semibold">Create User</h3>
                      </div>
                      <Row>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 2 }}
                        >
                          <Form onSubmit={handleSubmit}>
                            <Card className="p-5">
                              <Form.Group className="form-group">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                  name="emailAddress"
                                  type="text"
                                  placeholder="Enter user's email address"
                                  value={values.emailAddress}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.emailAddress &&
                                    touched.emailAddress &&
                                    "error"
                                  }
                                ></Form.Control>
                              </Form.Group>
                              {errors.emailAddress && touched.emailAddress ? (
                                <div className="input-feedback">
                                  {errors.emailAddress}
                                </div>
                              ) : null}
                              <Form.Group className="form-group">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                  name="firstName"
                                  type="text"
                                  placeholder="Enter user's first name"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.firstName &&
                                    touched.firstName &&
                                    "error"
                                  }
                                ></Form.Control>
                              </Form.Group>
                              {errors.firstName && touched.firstName ? (
                                <div className="input-feedback">
                                  {errors.firstName}
                                </div>
                              ) : null}
                              <Form.Group className="form-group">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                  name="lastName"
                                  type="text"
                                  placeholder="Enter user's last name"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.lastName &&
                                    touched.lastName &&
                                    "error"
                                  }
                                ></Form.Control>
                              </Form.Group>
                              {errors.lastName && touched.lastName ? (
                                <div className="input-feedback">
                                  {errors.lastName}
                                </div>
                              ) : null}
                              <Form.Group className="form-group">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  name="password"
                                  type="password"
                                  placeholder="Enter a password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.password &&
                                    touched.password &&
                                    "error"
                                  }
                                ></Form.Control>
                              </Form.Group>
                              {errors.password && touched.password ? (
                                <div className="input-feedback">
                                  {errors.password}
                                </div>
                              ) : null}
                              <Form.Group className="form-group">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                  name="confirmPassword"
                                  type="password"
                                  placeholder="Repeat password"
                                  value={values.confirmPassword}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    errors.confirmPassword &&
                                    touched.confirmPassword &&
                                    "error"
                                  }
                                ></Form.Control>
                              </Form.Group>
                              {errors.confirmPassword &&
                              touched.confirmPassword ? (
                                <div className="input-feedback">
                                  {errors.confirmPassword}
                                </div>
                              ) : null}
                              <Form.Group className="form-group permissions">
                                <h5>Permissions</h5>
                                {userPermissionDisplay.map((permission) => {
                                  if (permission.name === "View Sims") {
                                    return (
                                      <Form.Check
                                        key={permission.permissionId}
                                        label={permission.name}
                                        type="checkbox"
                                        value={permission.permissionId}
                                        checked={true}
                                        readOnly={true}
                                        name="recordPermissions"
                                        onChange={handleChange}
                                      ></Form.Check>
                                    );
                                  } else {
                                    return (
                                      <Form.Check
                                        key={permission.permissionId}
                                        label={permission.name}
                                        type="checkbox"
                                        value={permission.permissionId}
                                        name="recordPermissions"
                                        onChange={handleChange}
                                      ></Form.Check>
                                    );
                                  }
                                })}
                              </Form.Group>
                            </Card>
                            <div className="text-center my-5">
                              <button type="submit" className="btn btn-primary">
                                Create User
                              </button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              </Formik>
            </div>
          )}
        </Fragment>
      </Container>
      <CreateUserConfirmationPopup
        show={show}
        handleClose={handleClose}
        setText={popUpText}
        backToUserAdmin={backToUserAdmin}
      />
    </div>
  );
};

export default CreateUser;
