import { useState } from "react";
import config from "../../store/app.config";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const NewPasswordForm = () => {

    let { resetToken } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [noticeMessage, setNoticeMessage] = useState("");
    
    return(
        <Formik
      initialValues={{ newPassword: "", confirmPassword:"" }}
      onSubmit={(values) => {
        setIsLoading(true);
        const submitObject ={
          "Password": values.newPassword
        }
        fetch(`${config.API_BASE_URL}api/reset/${resetToken}`, {
          method: "POST",
          body: JSON.stringify(submitObject),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            setIsLoading(false);
            if (response.ok) { 
              setNoticeMessage(
                "Password reset please log back in again"
              );
            }
            if (response.status === 400) {
              throw Error(response.status);
            }
            if (response.status === 404) {
              setNoticeMessage("Failed");
            }
            if (response.status === 500) {
              throw Error(response.status);
            }
          })
          .catch((error) => {
            setErrorMessage(
              "Error when setting new password. If this" +
                " is the first time you've seen this, please try again. " +
                "Otherwise, please contact support."
            );
          });
      }}
      validationSchema={Yup.object().shape({
       newPassword: Yup.string().required('Password is required')
       .min(8, "Password too short. Must be at least 8 characters long."),
       confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="align-items-center justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="p-4">
                    <div className="text-center mt-2">
                      <h2 className="text-primary">Enter New Password</h2>
                      <p className="text-muted">
                        Please enter new password.
                      </p>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleSubmit}>
                        {errorMessage && (
                          <Alert variant="danger">{errorMessage}</Alert>
                        )}
                        {noticeMessage && (
                          <Alert variant="warning">{noticeMessage}</Alert>
                        )}
                        <Form.Group className="mb-3">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            name="newPassword"
                            type="password"
                            placeholder="Enter Password"
                            id="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.newPassword && touched.newPassword && "error"}
                            tabIndex={1}
                          />
                        </Form.Group>
                        {errors.newPassword && touched.newPassword && (
                          <div className="input-feedback">{errors.newPassword}</div>
                        )}
                        <Form.Group className="mb-3">
                          <Form.Label> Confirm New Password</Form.Label>
                          <Form.Control
                            name="confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                            id="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.confirmPassword && touched.confirmPassword && "error"}
                            tabIndex={1}
                          />
                        </Form.Group>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="input-feedback">{errors.confirmPassword}</div>
                        )}
                        <div className="d-grid gap-2">
                          {!isLoading && (
                            <Button
                              variant="primary"
                              type="submit"
                              tabIndex={2}
                            >
                              Reset Password
                            </Button>
                          )}
                          {isLoading && (
                            <Button variant="primary" disabled tabIndex={2}>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Sending request...
                            </Button>
                          )}
                        </div>
                      </Form>
                      <div className="mt-3 text-center">
                        <Link to="/auth">Return to sign in</Link>
                      </div>
                      <div className="p-2 mt-4"></div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    </Formik>
    );

    
}

export default NewPasswordForm;