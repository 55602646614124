import React, { Fragment } from "react";
import { useTable } from "react-table";
import moment from "moment";

const DashboardTable = ({
  data,
  title,
  getNewData,
  pageSize,
  total,
  currentPage,
}) => {
  const columns = React.useMemo(() => [
    {
      Header: title,
      columns: [
        {
          Header: "SIM Status",
          accessor: "statusName",
          disableSortBy: true,
          Cell: ({ value }) => {
            const statusType = [
              "Connected",
              "Not yet connected",
              "Available for hire",
              "Awaiting RA",
              "Migrate-in Started (SIM Management)",
              "other",
            ];
            return statusType.includes(value) ? (
              <span className={value.replace(/\s/g, "")}>{value}</span>
            ) : (
              value
            );
          },
        },
        {
          Header: "Serial No.",
          accessor: "serialNumber",
          disableSortBy: false,
        },
        { Header: "Tel No.", accessor: "telNumber", disableSortBy: false },
        { Header: "Network", accessor: "networkName", disableSortBy: true },
        { Header: "Tariff", accessor: "tariffName", disableSortBy: true },
        {
          Header: "Live from",
          accessor: "commencement",
          disableSortBy: true,
          Cell: (cellInfo) => {
            return (
              <Fragment>
                {cellInfo.row.original.commencement !== null &&
                  moment(cellInfo.row.original.commencement).format(
                    "Do MMM YYYY"
                  )}
              </Fragment>
            );
          },
        },
        {
          Header: "Data Usage",
          accessor: "dataUsageMb",
          disableSortBy: true,
          tipText: "Data usage may be up to 24 hours delayed",
          align: "right",
          Cell: (cellInfo) => {
            return (
              <Fragment>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {cellInfo.row.original.dataUsageMb !== null
                    ? cellInfo.row.original.dataUsageMb >= 1024
                      ? (cellInfo.row.original.dataUsageMb / 1024).toFixed(2) +
                        " GB"
                      : cellInfo.row.original.dataUsageMb.toFixed(1) + " MB"
                    : ""}
                </div>
              </Fragment>
            );
          },
        },
        {
          Header: "Bundle Size",
          accessor: "dataBundleMb",
          disableSortBy: true,
          align: "right",
          Cell: (cellInfo) => {
            return (
              <Fragment>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {cellInfo.row.original.dataBundleMb !== null
                    ? cellInfo.row.original.dataBundleMb >= 1024
                      ? (cellInfo.row.original.dataBundleMb / 1024).toFixed(2) +
                        " GB"
                      : cellInfo.row.original.dataBundleMb.toFixed(1) + " MB"
                    : ""}
                </div>
              </Fragment>
            );
          },
        },
        {
          Header: "Data Overage",
          accessor: "dataOverage",
          disableSortBy: true,
          align: "right",
          Cell: (cellInfo) => {
            return (
              <div
                style={{
                  textAlign: "right",
                }}
                className={
                  cellInfo.row.original.dataUsageMb /
                    cellInfo.row.original.dataBundleMb >
                  1
                    ? "text-danger"
                    : ""
                }
              >
                {cellInfo.row.original.dataBundleMb === null ||
                cellInfo.row.original.dataBundleMb === 0
                  ? ""
                  : (
                      (100 *
                        (cellInfo.row.original.dataUsageMb == null
                          ? 0.0
                          : cellInfo.row.original.dataUsageMb)) /
                      (cellInfo.row.original.dataBundleMb == null
                        ? 0.0
                        : cellInfo.row.original.dataBundleMb)
                    ).toFixed(2) + "%"}
              </div>
            );
          },
        },
        {
          Header: "Unbilled Spend",
          accessor: "billing",
          disableSortBy: true,
          align: "right",
          Cell: (cellInfo) => {
            return (
              <Fragment>
                {cellInfo.row.original.billingCurrency &&
                  cellInfo.row.original.billing !== null &&
                  cellInfo.row.original.billingCurrency}
                {cellInfo.row.original.billing == null
                  ? ""
                  : cellInfo.row.original.billing.toFixed(2)}
              </Fragment>
            );
          },
        },
      ],
    },
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="table-container">
      <table {...getTableProps()} className="stylish-table dashboard-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows.length === 0 && (
            <div className="no-sims">
              <span>No SIMs to display</span>
            </div>
          )}
          {/* This should be added but there is no Loading state here */}
          {/* {loading && (
            // Use our custom loading state to show a loading indicator
            <div className="loading">
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              <span>Loading...</span>
            </div>
          )} */}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
