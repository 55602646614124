import React, { useRef } from "react";
import { Formik, Field, Form } from "formik";
import { HiCheck, HiX } from "react-icons/hi";
import Overlay from "react-bootstrap/Overlay";
import * as Yup from "yup";
import "./SimLabel.scss";

const SimLabelForm = (props) => {
  const target = useRef(null);

  return (
    <div className="sim-label-form-container">
      <Formik
        initialValues={{ label: props.labelValue }}
        onSubmit={(values, {}) => {
          props.handleSubmit(props.serialNumber, values.label);
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().required("Label cannot be blank"),
        })}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
          return (
            <Form id="sim-label-form">
              <div ref={target}>
                <Field
                  id="label-input"
                  type="text"
                  name="label"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.label}
                  className={errors.label && touched.label && "error"}
                />
              </div>
              {errors.label && touched.label && (
                <Overlay target={target.current} show={true} placement="left">
                  {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div {...props} className="error-tooltip">
                      {errors.label}
                    </div>
                  )}
                </Overlay>
              )}
            </Form>
          );
        }}
      </Formik>
      <div className="button-container">
        <button form="sim-label-form" className="success" size="sm" variant="link" type="submit">
          <HiCheck />
        </button>
        <button type="button" onClick={() => props.Cancel(props.labelValue, true)}>
          <HiX />
        </button>
      </div>
    </div>
  );
};

export default SimLabelForm;
