import config from "../store/app.config";

const baseUrl = config.API_BASE_URL;

export const GetPortalRequests = async (request, token) => {
  try {
    const portalRequetsReponse = await fetch(`${baseUrl}api/request`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(request),
    });

    if (!(await portalRequetsReponse.ok)) {
      return { success: false, data: portalRequetsReponse.statusText };
    }

    return await portalRequetsReponse.json();
  } catch (error) {
    return { success: false, data: error };
  }
};

export const GetSimData = async (serialNumber, token) => {
  try {
    const portalRequetsReponse = await fetch(`${baseUrl}api/Sim/${serialNumber}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    });

    if (!(await portalRequetsReponse.ok)) {
      return { error: true, data: portalRequetsReponse.statusText };
    }

    return await portalRequetsReponse.json();
  } catch (error) {
    return { error: true, data: error };
  }
};

export const GetSelectedSimsRequest = async (request, token) => {
  try {
    const portalRequetsReponse = await fetch(`${baseUrl}api/Sim/serialNumbers`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(request),
    });

    if (!(await portalRequetsReponse.ok)) {
      return { error: true, data: portalRequetsReponse.statusText };
    }

    return await portalRequetsReponse.json();
  } catch (error) {
    return { error: true, data: error };
  }
};

export const GetSpendCapForSim = async (telNo, token) => {
  try {
    var url = `${baseUrl}/Sim/spendcap`;
    const portalRequetsReponse = await fetch(url + `?telNo=${telNo}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        tokenPortalId: "16867BF8-0B52-4008-9790-6D9FD3BF7530",
      }),
      body: JSON.stringify(portalRequetsReponse),
    });

    if (!(await portalRequetsReponse.ok)) {
      return { error: true, data: portalRequetsReponse.statusText };
    }

    return await portalRequetsReponse.json();
  } catch (error) {
    return { error: true, data: error };
  }
};

export const DeleteSimLabel = async (serialNumber, token, portalId) => {
  try {
    const portalRequestReponse = await fetch(`${baseUrl}api/Sim/deletelabel/${serialNumber}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        portalId: portalId,
        "Content-Type": "application/json",
      }),
    });

    if (!(await portalRequestReponse.ok)) {
      return { success: false, data: portalRequestReponse.statusText };
    }

    return await portalRequestReponse.json();
  } catch (error) {
    return { success: false, data: error };
  }
};

export const EditSimLabel = async (serialNumber, textLabel, token, portalId) => {
  try {
    var request = {
      serialNumber: serialNumber,
      textLabel: textLabel,
    };
    const portalRequestReponse = await fetch(`${baseUrl}api/Sim/editlabel`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        portalId: portalId,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(request),
    });

    if (!(await portalRequestReponse.ok)) {
      return { success: false, data: portalRequestReponse.statusText };
    }

    return await portalRequestReponse.json();
  } catch (error) {
    return { success: false, data: error };
  }
};

export const CreateSimLabel = async (serialNumber, textLabel, token, portalId) => {
  try {
    var request = {
      serialNumber: serialNumber,
      textLabel: textLabel,
    };
    const portalRequestReponse = await fetch(`${baseUrl}api/Sim/createlabel`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        portalId: portalId,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(request),
    });

    if (!(await portalRequestReponse.ok)) {
      return { success: false, data: portalRequestReponse.statusText };
    }

    return await portalRequestReponse.json();
  } catch (error) {
    return { success: false, data: error };
  }
};
