import { useState, useContext, Fragment } from "react";
import AuthContext from "../../store/auth-context";
import Terms from "../Terms";
import classes from "./Footer.module.scss";

function Footer(props) {
  const authCtx = useContext(AuthContext);
  const [showTerms, setShowTerms] = useState(false);
  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);

  return (
    <div className={classes.footer}>
      <div>
        <p className="small">
          &copy; {new Date().getFullYear()} simusage.com
          {authCtx.isLoggedIn && (
            <Fragment>
              |
              <span className={classes.termsBtn} onClick={handleShowTerms}>
                Terms of use
              </span>
              <Terms
                show={showTerms}
                handleShow={handleShowTerms}
                handleClose={handleCloseTerms}
              />
            </Fragment>
          )}
        </p>
      </div>
    </div>
  );
}

export default Footer;
