import React, {
  useState,
  Fragment,
  useEffect,
  useContext,
  useCallback,
} from "react";
import config from "../../../../store/app.config";

import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import "../Toolbar.scss";
import Table from "../../../UI/Table";
import AuthContext from "../../../../store/auth-context";

const Bar = (props) => {
  const [barIsComplete, setBarIsComplete] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [optionChosen, setOptionChosen] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const authCtx = useContext(AuthContext);

  const handleCloseBar = () => {
    setBarIsComplete(false);
    setOptionChosen("");
    setIsLoading(false);
    setError(null);
    props.handleClose();
  };

  // Receives selected SIMs, adds a serialnumber key so that it can be interpreted by the table
  useEffect(() => {
    const objectArray = [];
    if (props.selectedSimArray.length > 0) {
      props.selectedSimArray.forEach((element) =>
        objectArray.push({ serialNumber: element })
      );
      setTableData(objectArray);
    }
  }, [props.selectedSimArray]);

  const submitBarringSimHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${config.API_BASE_URL}api/sim/${optionChosen}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${authCtx.token}`,
            "Content-Type": "application/json",
          }),
          body: JSON.stringify({
            serialNumbers: props.selectedSimArray,
          }),
        }
      );
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data);
      }
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      return error;
    }
    setBarIsComplete(true);
    setOptionChosen("");
  }, [authCtx, optionChosen, props.selectedSimArray]);

  // Columns on small Barring table
  const columns = [
    { Header: "Selected SIMs", accessor: "serialNumber", show: true },
  ];

  const radioHandler = (e) => {
    setOptionChosen(e.target.id);
  };

  return (
    <Modal className="bar actions" show={props.show} centered>
      <Modal.Header>
        <Modal.Title>Barring</Modal.Title>
        <Button
          variant="modal-close"
          aria-label="Close"
          onClick={handleCloseBar}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      {(!barIsComplete && (
        <Fragment>
          <Modal.Body>
            {error && (
              <Alert className="error-alert" variant="danger">
                <BsFillXCircleFill className="failure-icon" size={20} /> Error:{" "}
                {error}
              </Alert>
            )}
            <Table columns={columns} data={tableData} />
            <Form className="mb-3">
              <Form.Check
                type="radio"
                name="barOrUnbar"
                id="bar"
                label="I want to bar all the above"
                onChange={radioHandler}
              />
              <Form.Check
                type="radio"
                name="barOrUnbar"
                id="unbar"
                label="I want to unbar all the above"
                onChange={radioHandler}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="default" onClick={handleCloseBar}>
              Cancel
            </Button>
            {!isLoading && (
              <Button
                variant="primary"
                onClick={submitBarringSimHandler}
                disabled={!optionChosen}
              >
                Confirm
              </Button>
            )}
            {isLoading && (
              <Button
                variant="primary"
                onClick={submitBarringSimHandler}
                disabled={true}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {""}
                Sending...
              </Button>
            )}
          </Modal.Footer>
        </Fragment>
      )) ||
        (barIsComplete && (
          <Fragment>
            <Modal.Body className="text-center">
              <div className="error-success">
                <BsFillCheckCircleFill className="success-icon" size={35} />
                <h5>Bar Request Sent</h5>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="primary" onClick={handleCloseBar}>
                Close
              </Button>
            </Modal.Footer>
          </Fragment>
        ))}
    </Modal>
  );
};

export default Bar;
