import React, { useContext, Fragment } from "react";
import AuthContext from "../../../store/auth-context";

import "./Toolbar.scss";

const Toolbar = (props) => {
  const authCtx = useContext(AuthContext);

  let toolbarTextColour = "light";
  if (authCtx.decodedToken.portal_primarytextcolour === "dark") {
    toolbarTextColour = "dark";
  }

  return (
    <Fragment>
      <div className="toolbar" textcolour={toolbarTextColour}>
        {props.children}
      </div>
    </Fragment>
  );
};

export default Toolbar;
