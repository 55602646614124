import RequestList from "../components/Requests/RequestList";

const RequestListPage = () => {
  return (
    <>
      <RequestList />
    </>
  );
};

export default RequestListPage;
