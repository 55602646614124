import React, { useEffect, useState, useRef, useCallback, Fragment } from "react";

import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { Button, Spinner, FormControl, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";
import "./MainTable.scss";
import {
  HiSearch,
  HiXCircle,
  HiChevronLeft,
  HiChevronRight,
  HiOutlineInformationCircle,
  HiOutlineArrowDown,
  HiOutlineArrowUp,
} from "react-icons/hi";
import { propTypes } from "react-bootstrap/esm/Image";

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Fragment>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </Fragment>
  );
});

const defaultPropGetter = () => ({});

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function MainTable({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  onSelectListUpdate,
  onPageSizeUpdate,
  clearSelection,
  hiddenCols,
  getCellProps = defaultPropGetter,
  getRowId,
  enableSelection,
  enablePagination,
  enableSearch,
  perPageLabel,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: hiddenCols,
      },
      manualSortBy: true,
      manualPagination: true, // Tell the usePagination hook that we'll handle our own data fetching. This means we'll also have to provide our own pageCount.
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      getRowId: getRowId,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case "toggleAllRowsSelected":
            return {
              ...newState,
              selectedRowIds: {},
            };
          default:
            return newState;
        }
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (enableSelection) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      } else {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => <div></div>,
            // The cell can use the individual row's getToggleRowSelectedProps method to the render a checkbox
            Cell: ({ row }) => <div></div>,
          },
          ...columns,
        ]);
      }
    }
  );

  const [searchTerm, setSearchTerm] = useState("");
  const searchBar = useRef(null);

  const searchHandler = (e) => {
    setCharLengthTooShort(false);

    // The search will trigger if the Enter key is pressed, or if the SearchHandler is triggered without a keystroke (when the search button is pressed).
    if (e.key === "Enter" || !e.key) {
      if (searchBar.current.value.length < 5 && searchBar.current.value.length > 0) {
        console.log("too short");
        setCharLengthTooShort(true);
        console.log(searchBar.current);
        return;
      } else if (searchBar.current.value.length === 0) {
        fetchData({
          pageSize: pageSize,
          pageIndex: 0,
          sortByField: sortBy.id,
          sortByASC: !sortBy.desc,
        });
      } else {
        console.log("fine");
        setCharLengthTooShort(false);

        fetchData({
          pageSize: pageSize,
          pageIndex: 0,
          searchTerm: searchBar.current.value,
          searchField: searchBy,
          sortBy: sortBy,
        });
      }
    }
  };

  const [charLengthTooShort, setCharLengthTooShort] = useState(false);
  const handleInput = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearchHandler = () => {
    // Clears search term to clear the input
    setCharLengthTooShort(false);
    setSearchTerm("");
    // Fetch data again without a search term to return all results
    fetchData({
      pageSize: pageSize,
      pageIndex: 0,
      sortBy: sortBy,
    });
  };

  const clearSelectionHandler = useCallback(() => {
    toggleAllRowsSelected(false);
  }, [toggleAllRowsSelected]);

  // Refresh function, passed through props
  useEffect(() => {
    clearSelection.current = clearSelectionHandler;
  }, [clearSelection, clearSelectionHandler]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  // Send selected Row IDs up to the SimControlTable
  useEffect(() => {
    onSelectListUpdate(selectedRowIds);
  }, [selectedRowIds]);

  // Passes page size up for refreshing
  useEffect(() => {
    onPageSizeUpdate(pageSize);
  }, [pageSize]);

  // Reset table scroll to top on load
  const tableContainer = useRef(null);
  useEffect(() => {
    tableContainer.current.scrollTop = 0;
  }, [loading]);

  // Search by... dropdown logic
  const [searchBy, setSearchBy] = useState("telNumber");
  const searchByHandler = (val) => {
    setSearchBy(val);
    clearSearchHandler();
  };

  const getPerPageLabel = () => {
    if (perPageLabel) {
      return perPageLabel;
    } else {
      return "SIMs";
    }
  };
  const renderSearchBox = () => {
    if (enableSearch) {
      return (
        <div className="search">
          <InputGroup size="sm" className="mb-1">
            <DropdownButton
              onSelect={searchByHandler}
              variant="light"
              title={searchBy === "telNumber" ? "Tel No." : "Serial No."}
            >
              <Dropdown.Item eventKey="telNumber">Tel No.</Dropdown.Item>
              <Dropdown.Item eventKey="serialNumber">Serial No.</Dropdown.Item>
            </DropdownButton>
            <Fragment>
              <FormControl
                aria-label="Text input with dropdown button for searching table"
                placeholder={searchBy === "telNumber" ? "Search by phone number" : "Search by serial number"}
                onKeyDown={searchHandler}
                onChange={handleInput}
                value={searchTerm}
                ref={searchBar}
                className={charLengthTooShort ? "invalid" : ""}
              />
              {!(searchTerm === "") && (
                <Button variant="light" className="btn-tiny" onClick={clearSearchHandler} aria-label="Clear search box">
                  <HiXCircle className="icon" />
                </Button>
              )}
            </Fragment>
            <Button variant="light" onClick={searchHandler} aria-label="Submit search">
              <HiSearch className="icon" />
            </Button>
            {charLengthTooShort && <span className="invalid-feedback">Please enter at least 5 characters</span>}
          </InputGroup>
        </div>
      );
    }
  };

  // Render the UI for your table
  return (
    <Fragment>
      <div className="table-group main-table">
        <div className="table-group-head">
          <div className="table-group-head-left">
            {renderSearchBox()}
            {Object.keys(selectedRowIds).length > 0 && (
              <div className="sims-selected">
                <span>
                  {Object.keys(selectedRowIds).length} SIM
                  {Object.keys(selectedRowIds).length > 1 && "s"} selected
                </span>
                <Button variant="light" className="btn-tiny" onClick={clearSelectionHandler}>
                  <HiXCircle className="icon" />
                </Button>
              </div>
            )}
          </div>
          <div className="details">
            {!loading && (
              <span className="mx-3">
                Showing {page.length} of ~{controlledPageCount * pageSize} results
              </span>
            )}
            <span className="mx-4">
              <label htmlFor="goToPageInput">Go to page:</label>
              <input
                id="goToPageInput"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </span>{" "}
            <label>{getPerPageLabel()} per page:</label>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-container-container">
          <div ref={tableContainer} className={`table-container ${loading && "loading"}`}>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        className={
                          (headerGroup.headers[index].tipText ? "table-tooltip " : "") +
                          (headerGroup.headers[index].align ? headerGroup.headers[index].align : "")
                        }
                      >
                        {column.render("Header")}
                        {headerGroup.headers[index].tipText && (
                          <Fragment>
                            <HiOutlineInformationCircle />
                            <span className="table-tooltiptext">{headerGroup.headers[index].tipText}</span>
                          </Fragment>
                        )}
                        <span>
                          {column.disableSortBy === false && column.isSorted ? (
                            column.isSortedDesc ? (
                              <HiOutlineArrowDown />
                            ) : (
                              <HiOutlineArrowUp />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps([{ className: cell.column.className }, getCellProps(cell)])}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {page.length === 0 && (
              <div className="no-results">
                <span>No results found.</span>
              </div>
            )}
            {loading && (
              // Use our custom loading state to show a loading indicator
              <div className="loading p-0">
                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
                <span>Loading...</span>
              </div>
            )}
          </div>
        </div>
        <div className="pagination">
          {/* Previous page */}
          <button onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="Previous page">
            <HiChevronLeft className="icon" />
          </button>
          {/* First page */}
          {pageIndex >= 2 && (
            <button onClick={() => gotoPage(0)} aria-label="First page">
              1
            </button>
          )}
          {/* Spacer */}
          {pageIndex > 2 && (
            <span className="spacer" aria-hidden="true">
              ...
            </span>
          )}
          {/* Antepenultimate page */}
          {pageIndex > 3 && pageIndex >= controlledPageCount - 2 && controlledPageCount > 3 && (
            <button onClick={() => gotoPage(controlledPageCount - 3)} aria-label="Go back two pages">
              {pageIndex - 1}
            </button>
          )}
          {/* Previous page */}
          {pageIndex >= 1 && (
            <button onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="Previous page">
              {pageIndex}
            </button>
          )}
          {/* Current page */}
          <button className="current" aria-label="Current page">
            {pageIndex + 1}
          </button>
          {/* Next page */}
          {pageIndex <= controlledPageCount - 2 && (
            <button onClick={() => nextPage()} disabled={!canNextPage} aria-label="Next page">
              {pageIndex + 2}
            </button>
          )}
          {/* Page 3 */}
          {pageIndex === 0 && controlledPageCount >= 3 && (
            <button onClick={() => gotoPage(2)} aria-label="Go forward two pages">
              3
            </button>
          )}
          {/* Spacer */}
          {pageIndex < controlledPageCount - 3 && controlledPageCount > 5 && (
            <span className="spacer" aria-hidden="true">
              ...
            </span>
          )}
          {/* Last page */}
          {pageIndex < controlledPageCount - 2 && controlledPageCount > 3 && (
            <button onClick={() => gotoPage(pageCount - 1)} aria-label="Last page">
              {controlledPageCount}
            </button>
          )}
          {/* Next page */}
          <button onClick={() => nextPage()} disabled={!canNextPage} aria-label="Next page">
            <HiChevronRight className="icon" />
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default MainTable;
