import { useTable, useRowSelect } from "react-table";
import React, { useEffect, useState, useCallback } from "react";
import { Stack, Card, Button } from "react-bootstrap";
import { HiChevronLeft, HiChevronRight, HiXCircle } from "react-icons/hi";

const SimsAvailable = ({
  data,
  setSelectedAvailableSims,
  getNewData,
  pageSize,
  total,
  currentPage,
  setCurrentPage,
  onAvailableSimListUpdate,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  });
  const onRowSelect = (e) => {
    let selectedSerailNumbers = [];
    e.forEach((element) => {
      let serialNo = element.values;
      selectedSerailNumbers.push(serialNo);
    });
    setSelectedAvailableSims(selectedSerailNumbers);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelect(selectedFlatRows);
    // Sends selected rows to component above (AddSimComponent)
    onAvailableSimListUpdate(selectedRowIds);
  }, [selectedRowIds]);

  const searchHandler = (e) => {
    if (e.key === "Enter") {
      setSearchTerm(e.target.value);
      getNewData(e.target.value, 1, pageSize);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(currentPage - 1);
      getNewData(searchTerm, newPage, pageSize);
    }
  };

  const nextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(currentPage + 1);
    getNewData(searchTerm, newPage, pageSize);
  };

  const clearSelectionHandler = useCallback(() => {
    toggleAllRowsSelected(false);
  }, [toggleAllRowsSelected]);

  return (
    <Card className="p-5">
      <Stack gap={2}>
        <h4 className="text-center">Available SIMs</h4>
        <div className="table-container basic-table checkboxed">
          <div className="search">
            <input aria-label="Search" onKeyDown={searchHandler} placeholder="Search"></input>
          </div>
          <div className="sims-selected">
            {Object.keys(selectedRowIds).length > 0 && (
              <>
                <span>
                  {Object.keys(selectedRowIds).length} SIM
                  {Object.keys(selectedRowIds).length > 1 && "s"} selected
                </span>
                <Button variant="light" className="btn-tiny" onClick={clearSelectionHandler}>
                  <HiXCircle className="icon" />
                </Button>
              </>
            )}
          </div>
          <div className="sim-table-container">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {total > 0 && (
            <div className="pagination">
              <button onClick={previousPage} disabled={currentPage < 2}>
                <HiChevronLeft className="icon" />
              </button>
              <button className="current">{currentPage}</button>
              <button onClick={nextPage} disabled={currentPage * pageSize > total}>
                <HiChevronRight className="icon" />
              </button>
            </div>
          )}
        </div>
      </Stack>
    </Card>
  );
};

export default SimsAvailable;
