import React, { useState, useCallback, useContext, useEffect } from "react";
import config from "../../store/app.config";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { IoList } from "react-icons/io5";
import { VscArrowSwap } from "react-icons/vsc";

import ProfileMenu from "./ProfileMenu";
import "./Header.scss";

const Header = (props) => {
  const authCtx = useContext(AuthContext);
  const [logo, setLogo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  let menuBarClass = "dark";
  if (authCtx.decodedToken.menuBarTheme === "light") {
    menuBarClass = "light";
  }

  const fetchLogoHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${config.API_BASE_URL}api/portal/${authCtx.decodedToken.portal_id}/logo`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${authCtx.token}`,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong!");
      }
      const data = await response.text();
      setLogo(data);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, [authCtx]);

  useEffect(() => {
    fetchLogoHandler();
  }, [fetchLogoHandler]);

  if (error) {
    <p>{error}</p>;
  }

  if (isLoading) {
    <p>Loading...</p>;
  }

  return (
    <div>
      <Navbar className={menuBarClass}>
        <Container fluid>
          <Navbar.Brand as={Link} to="/sims">
            {logo.length !== 0 ? (
              <img
                id="logo"
                src={"data:image/png;base64," + logo}
                alt={authCtx.decodedToken.portal_name}
                height="40px"
              />
            ) : (
              <div height="40px">
                <h2>{authCtx.decodedToken.portal_name}</h2>
              </div>
            )}
          </Navbar.Brand>
          <div className="top-nav-links">
            {/* <Nav.Link href="/dashboard">
              <CgScreen />
              <span>Dashboard</span>
            </Nav.Link> */}
            <Nav.Link href="/sims">
              <IoList />
              <span>SIM List</span>
            </Nav.Link>
            <Nav.Link href="/requests">
              <VscArrowSwap />
              <span>Request Log</span>
            </Nav.Link>
          </div>
          <ProfileMenu />
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
