import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import { Container } from "react-bootstrap";
import EditBrandingComponent from "./EditBrandingComponent";
import "./Branding.scss";

const EditBrandingPage = (props) => {
  const location = useLocation();
  let history = useHistory();
  const [loading, setLoading] = useState(true);
  const [portalId, setPortalId] = useState("");
  const [portalName, setPortalName] = useState("");

  useEffect(() => {
    if (location.state) {
      setPortalId(location.state.portalId);
      setPortalName(location.state.portalName);
    }
    setLoading(false);
  }, [location]);

  const BackToDashboard = () => {
    history.push({
      pathname: "/adminDashboard",
      state: { portalId: portalId, portalName: portalName },
    });
  };

  return (
    <>
      <Toolbar>
        <h2>{portalName}</h2>
        <ToolbarButton
          label="Dashboard"
          action="back"
          onClick={BackToDashboard}
        ></ToolbarButton>
      </Toolbar>
      <Container>
        <div className="text-center my-5">
          <h2 className="font-weight-semibold">Edit Branding: {portalName}</h2>
        </div>

        {!loading && (
          <EditBrandingComponent
            portalId={portalId}
            portalName={portalName}
          ></EditBrandingComponent>
        )}
      </Container>
    </>
  );
};

export default EditBrandingPage;
