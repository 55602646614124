import { Fragment, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AuthContext from "../../store/auth-context";

const Layout = (props) => {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <Fragment>
      {isLoggedIn && <Header />}
      <main>{props.children}</main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
