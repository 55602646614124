import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Container, Spinner, ListGroup, ListGroupItem, InputGroup  } from "react-bootstrap";
import { Fragment, useEffect, useState, useContext } from "react";
import { GetUser } from "../../services/UserServices";
import { GetPortalsTreeData, CreatePortal, GetPortalData } from "../../services/PortalService";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import "../../../node_modules/react-simple-tree-menu/dist/main.css";
import AuthContext from "../../store/auth-context";
import PortalDetailsModal from "./PortalDetailsModal"
import ConfirmationModal from "../UI/ConfirmationModal";
import Form from 'react-bootstrap/Form';
import { FaUserEdit, FaUserTimes } from "react-icons/fa";
import {MdAdd, MdEdit} from "react-icons/md"
import { LabelList } from "recharts";

const PortalPage = () => {
  let history = useHistory();
  let { userId } = useParams();
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [usersPortalId, setUserPortalId] = useState("");
  const [treeData, setTreeData] = useState(null);
  const [openNodes, setOpenNodes] = useState([]);
  const [selectedPortal, setSelectedPortal] = useState({portalId:"", portalName:""})
  const [show, setShow] = useState(false); 
  const [newPortalData , setNewPortalData] = useState({name: '', showTariff: false, showBilling: false, AllowExport: false })
  const [confirmationShow, setConfirmationShow] = useState(false); 
  const LogOut = () => {
    history.push("/auth");
  };
 

  const ToPortalDashboard = async (key, label) => {
    setLoading(true);
    let portalId = key.split("/").pop();
    setSelectedPortal({portalId: portalId, portalName: label});
    history.push({
      pathname: "/adminDashboard",
      state: { portalId: portalId, portalName: label },
    });
  };

  const ShowNewPortalModal = (key, label) =>{
    let portalId = key.split("/").pop();
    setSelectedPortal({portalId: portalId, portalName: label});
    setShow(true); 
  }

  const handleClose = () => {
    setShow(false);
    setConfirmationShow(false); 
    
  };

  useEffect(() => {
    GetUser(userId, authCtx.token).then((result) => {
      let nodesToOpen = [];
      if (!result.error && result.portalId) {
        setUserPortalId(result.portalId);
        GetPortalsTreeData(result.portalId, authCtx.token).then((response) => {
          if (response.success) {
            setTreeData(response.data);
            nodesToOpen.push(response.data[0].key);
            setOpenNodes(nodesToOpen);
          } else {
            setUserMessage("Could not get portals");
            setConfirmationShow(true); 
          }

          setLoading(false);
        });
      } else {
        setUserMessage("Could not find user to get portals");
        setConfirmationShow(show)
        setLoading(false);
      }
    });
  }, [newPortalData]);

  const CreateNewPortal =  async (newPortalName, showTariff, showBilling, ExportVisible)=>{

    handleClose(); 
    setLoading(true); 

   const parentPortal = await GetPortalData(selectedPortal.portalId, authCtx.token); 

   if( await parentPortal.error) {
     setUserMessage("Failed To Get Portal Details"); 
     setConfirmationShow(true);
   }else{

    const newPortalRequest = {
      "Name": newPortalName, 
      "ContactEmail": authCtx.decodedToken.email, 
      "PrimaryColour": parentPortal.primaryColour, 
      "PrimaryTextColour": parentPortal.primaryTextColour, 
      "SecondaryColour": parentPortal.secondaryColour, 
      "SecondaryTextColour": parentPortal.secondaryTextColour, 
      "ButtonColour": parentPortal.buttonColour, 
      "ButtonTextColour": parentPortal.buttonTextColour, 
      "MenuBarColour": parentPortal.menuBarColour, 
      "TariffVisible": false, 
      "BillingVisible": false, 
      "ExportVisible": false, 
      "Active": true, 
      "ParentPortalId": selectedPortal.portalId, 
      "TariffVisible": showTariff,
      "BillingVisible":showBilling,  
      "ExportVisible": ExportVisible
    }

    const newPortalCreated =  await CreatePortal(newPortalRequest, authCtx.token, authCtx.decodedToken.portal_id); 

    if(await newPortalCreated.success){
      setNewPortalData({"name": newPortalName});
      setUserMessage("Portal Created") 
      setConfirmationShow(true); 
    }else{
      setUserMessage("Failed To Create Portal")
      setConfirmationShow(true); 
    }

   }

   setLoading(false); 

  }

  return (
    <div>
      <Toolbar>
        <h2>Portals</h2>
        <ToolbarButton
          label="Exit Admin"
          onClick={LogOut}
          action="back"
        ></ToolbarButton>
      </Toolbar>
      <Container>
        {loading && (
          <div className="text-center my-5">
            <div className="loading">
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              <span>Loading...</span>
            </div>
          </div>
        )}
        <Row className="mt-3">
          <Col>
            {!loading && (
              <Fragment>
                <TreeMenu
                  data={treeData}
                  initialOpenNodes={openNodes}
                >
                 {({search, items}) => (
                  <Fragment>
                     <input class="rstm-search" onChange={e => search(e.target.value)} placeholder="Search" />
                    <ListGroup component="nav" class="rstm-tree-item-group">
                    {items.map(({key, label,style, ...props}) => (
                      <Row key={key} style={style}>
                        <Col key={key} >
                        <ItemComponent key={key} label={label} {...props}  />
                        </Col>
                        <Col xs lg="3" >
                        <div className="PortalButtons" >
                              <button className="btn btn-link edit" onClick={() => ToPortalDashboard(key, label)}><MdEdit/> Edit</button>
                              <button className="btn btn-link edit" onClick={() => ShowNewPortalModal(key, label)} style={{marginLeft: "2rem"}}><MdAdd/> New subportal</button>
                          </div>
                        </Col>
                        </Row>
                      ))}
                    </ListGroup>
                  </Fragment>
                  )}
                </TreeMenu>
              </Fragment>
            )}
            {/* {!loading && errorMessage.length > 0 && <span>{errorMessage}</span>} */}
          </Col>
        </Row>
        
      </Container>
      <PortalDetailsModal
      show={show}
      handleClose={handleClose}
      CreatePortal={CreateNewPortal} 
      />
      {<ConfirmationModal
      show={confirmationShow}
      handleClose={handleClose}
      title={"Create Portal"}
      text={userMessage}
      />}
    </div>
  );
};

export default PortalPage;
