import React from "react";

import "./Toolbar.scss";

const ToolbarButton = ({ children, ...props }) => {
  const toolbarButtonClasses = props.size === "small" ? "small" : "";

  return (
    <button
      {...props}
      className={`toolbarButton custom-tooltip ${toolbarButtonClasses}`}
      action={props.action}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <i>{props.icon}</i>
      
      {props.label}
      {children}
    </button>
  );
};

export default ToolbarButton;
