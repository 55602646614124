import { Formik } from "formik";
import { Form, Row, Col } from "react-bootstrap";
import * as Yup from "yup";

const CompanyNameChange = (props) => {
  const updateChange = (value) => {
    props.updateCompanyName(value);
  };

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().min(5, "Too Short"),
  });

  return (
    <>
      <Formik
        initialValues={{ companyName: props.companyName }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const { values, touched, errors } = props;
          return (
            <Form>
              <Row>
                <Col xs={12} md={8} lg={5}>
                  <Form.Group>
                    <Form.Label>
                      <strong>Company Name</strong>
                    </Form.Label>
                    <Form.Control
                      name="companyName"
                      type="text"
                      value={values.companyName}
                      onChange={(e, val) => {
                        updateChange(e.currentTarget.value);
                        values.companyName = e.currentTarget.value;
                      }}
                    ></Form.Control>
                    {errors.companyName && touched.companyName ? (
                      <div className="text-danger">{errors.companyName}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CompanyNameChange;
