import React, { useState, useContext, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import Toolbar from "../Toolbar/Toolbar";
import ToolbarButton from "../Toolbar/ToolbarButton";
import SimControlTable from "./SimControlTable";
import Bar from "../Toolbar/Actions/Bar";
import SimSwap from "../Toolbar/Actions/SimSwap";
import SimExport from "../Toolbar/Actions/SimExport";
import "../Toolbar/Toolbar.scss";

const SimControl = () => {
  let history = useHistory();

  const authCtx = useContext(AuthContext);

  const [showBar, setShowBar] = useState(false);
  const handleCloseBar = () => setShowBar(false);
  const handleShowBar = () => setShowBar(true);

  const [showSimSwap, setShowSimSwap] = useState(false);
  const handleCloseSimSwap = () => setShowSimSwap(false);
  const handleShowSimSwap = () => setShowSimSwap(true);

  const [showSimExport, setShowSimExport] = useState(false);
  const handleCloseSimExport = () => setShowSimExport(false);
  const handleShowSimExport = () => setShowSimExport(true);

  const [selectedSimArray, setSelectedSimArray] = useState({});
  const [pageSize, setPageSize] = useState({});

  // Creates an array from the selected SIMs consisting of only the serial numbers, to be passed to various action components
  const selectListUpdateHandler = (selectedRowIds) => {
    setSelectedSimArray(Object.keys(selectedRowIds));
  };

  // Receives pageSize from MainTable below, stores it in a state at this level, to be used by Refresh Handler
  const pageSizeUpdateHandler = (pageSize) => {
    setPageSize(pageSize);
  };

  // Reference to the refresh and clearSelection functions below in SimControlTable
  const refresh = useRef(null);
  const clearSelection = useRef(null);
  const refreshHandler = () => {
    refresh.current({ pageSize: pageSize, pageIndex: 0 });
    clearSelection.current();
  };

  // Determining account permissions
  const scopeList = {
    admin: authCtx.decodedToken.scope.includes("Admin"),
    viewSims: authCtx.decodedToken.scope.includes("ViewSims"),
    barSim: authCtx.decodedToken.scope.includes("BarSim"),
    swapSim: authCtx.decodedToken.scope.includes("SwapSim"),
    export: authCtx.decodedToken.scope.includes("ViewSims"),
  };

  return (
    <div id="sim-control">
      <Toolbar>
        <h2>Manage SIMs</h2>
        {scopeList.barSim && (
          <Fragment>
            <ToolbarButton
              action="bar"
              label="Barring"
              onClick={handleShowBar}
              disabled={!selectedSimArray.length > 0}
            >
              <span className="custom-tooltiptext">
                Select a SIM to start Barring
              </span>
            </ToolbarButton>
            <Bar
              show={showBar}
              handleShow={handleShowBar}
              handleClose={handleCloseBar}
              selectedSimArray={selectedSimArray}
            />
          </Fragment>
        )}
        {scopeList.swapSim && (
          <Fragment>
            <ToolbarButton
              action="simSwap"
              label="SIM Swap"
              onClick={handleShowSimSwap}
              disabled={selectedSimArray.length !== 1}
            >
              <span className="custom-tooltiptext">
                Select only 1 SIM to start SIM swap
              </span>
            </ToolbarButton>

            <SimSwap
              show={showSimSwap}
              handleShow={handleShowSimSwap}
              handleClose={handleCloseSimSwap}
              selectedSimArray={selectedSimArray}
            />
          </Fragment>
        )}

        <ToolbarButton
          action="refresh"
          label="Refresh"
          size="small"
          onClick={refreshHandler}
        />
        <Fragment>
          {scopeList.export && (
            <ToolbarButton
              action="export"
              label="Export"
              size="small"
              onClick={handleShowSimExport}
            />
          )}

          <SimExport
            show={showSimExport}
            handleShow={handleShowSimExport}
            handleClose={handleCloseSimExport}
            selectedSimArray={selectedSimArray}
          />
        </Fragment>
      </Toolbar>
      {scopeList.viewSims ? (
        <SimControlTable
          onSelectListUpdate={selectListUpdateHandler}
          onPageSizeUpdate={pageSizeUpdateHandler}
          refresh={refresh}
          clearSelection={clearSelection}
          history={history}
        />
      ) : (
        "You are not authorised to view sims"
      )}
    </div>
  );
};

export default SimControl;
