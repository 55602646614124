import config from "../store/app.config"


const baseUrl = config.API_BASE_URL; 

export  const GetPortalData = async (portalId, token)=>{

    try
    {
        const response = await fetch(`${baseUrl}api/Portal/${portalId}`,
        {
            method: 'GET', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                
            })
        })
        
        if(response.ok){
            return  await response.json();
        }
        else 
        {
            return { "error": false, "data": response.text};
        }
       
    } 
    catch(error)
    {
        return { "error": false, "data": error};
    }
   
}

export const GetPortalsTreeData = async (portalId, token)=>{

    try{
        const response = await fetch(`${baseUrl}api/portal/tree`,
        {
            method: 'GET', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                
            })
        })
        if(await response.ok){
            return {"success": true, "data": await response.json()}
        }else{
            return { "success": false, "data": null}
        }
    }catch(error){
        return { "success": false, "data": error}
    }
}

export const UpdatePortalServerCall = async (portal, token)=>{

    try{
        const portalUpdateRequest = {
            id:portal.id,
            name: portal.name, 
            logoLocation: "",
            contactEmail: portal.contactEmail,
            primaryColour: portal.primaryColour,
            primaryTextColour: portal.primaryTextColour, 
            secondaryColour: portal.secondaryColour, 
            secondaryTextColour: portal.secondaryTextColour, 
            buttonColour: portal.buttonColour, 
            buttonTextColour: portal.buttonTextColour, 
            menuBarColour: portal.menuBarColour, 
            active : portal.active, 
            parentPortalId: portal.parentPortalId
            
        }
    
        const portalUpdateResponse = await fetch(`${baseUrl}api/portal/update`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              
             
            }),body: JSON.stringify(portalUpdateRequest)
          }); 
          
          
          if( await portalUpdateResponse.ok){
     
            return {"success": true}
          }else{

            var data = await portalUpdateResponse.json();
            return {"success": false, 
                    "errorMessage": portalUpdateResponse.body
                   }
    
          }
    }catch(error){
      return   {"success": false, 
                "errorMessage": error
                }
    }
    
}

export const convertFileToImageString =  (file)=>{

    return new Promise((resolve, reject ) => {

        const reader = new FileReader(); 
        
         reader.onloadend = () => {
        // Use a regex to remove data url part
        var  base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

                resolve(base64String); 
    
        }
        reader.onerror = reject; 
         reader.readAsDataURL(file);
    })
}

const convertStreamToTextForImage = async (logoResponse)=>{

    try{
        const reader =  await logoResponse.body.getReader();

         const stream = await  new ReadableStream({
             start(controller){
                 function push() {
                     reader.read().then(({done, value})=>{
                         if(done){
                            
                             controller.close();
                             return; 
                         }
                         controller.enqueue(value); 
                         
                         push()
                     })
                 }

                 push();
             }
         })
         
         const  newResponse = await new Response(stream, {headers: {"Content-Type":"text/html"}}).text(); 
         
        return newResponse;

     }
     catch(Err){
         console.log(Err); 
         return null; 
     }
}

export const GetLogoFromServer = async (portalId, token)=>{

    try{
        const response = await fetch(`${baseUrl}api/portal/${portalId}/logo`,
        {
            method: 'GET', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "text/html",
               
            })
        })
        if(!response.ok){
            return {'success': false, 'data':'', "logoFound": true}
        }
        if(response.status === 404){
            return {'success': true, 'data':"", "logoFound": false}
        }
        var logoString =   await convertStreamToTextForImage(response);
        if(await logoString != null){
            return {'success': true, 'data':logoString, "logoFound": true}
        }else{
            return {'success': false, 'data':logoString, "logoFound": true}
        }
    }catch(error){
        return {'success': false, 'data':error, "logoFound": false}
    }
   
}

export const UploadLogoToServer = async (file, portalId, token)=>{

    try{
        var data = new FormData(); 
        data.append('image', file)

        const logoUpdateResponse = await fetch(`${baseUrl}api/portal/${portalId}/logo`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
         
        }),body: data
        }); 

        if(await logoUpdateResponse.ok){
            return {"success": true}
        }else{
            return {"success": false, 
                    "errorMessage": logoUpdateResponse.body}
        }
    }catch(error){
        return {"success": false, 
        "errorMessage": error}
    }
    
         
}

export const GetAvailableSims = async ( request, token)=>{

    try
    {
        const response = await fetch(`${baseUrl}api/SimAssignment/user/`,
        {
            method: 'POST', 
            headers: new Headers({
                 Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                
                
                
               
            }), body: JSON.stringify(request)
        })
        
        if(!await response.ok ){

            return {"error":true, "data": response.statusText}
        }
        return  await response.json();
    } 
    catch(error)
    {
        return { "error": true, "data": error}
    }
}


export const GetAvailableSimsForPortal = async ( request, token)=>{

    try
    {
        const response = await fetch(`${baseUrl}api/SimAssignment/portal/`,
        {
            method: 'POST', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                
                
               
            }), body: JSON.stringify(request)
        })
        
        if(!await response.ok ){

            return {"error":true, "data": response.statusText}
        }
        return  await response.json();
    } 
    catch(error)
    {
        return { "error": true, "data": error}
    }
}

export const AssignSimsToPortal = async (request, token )=>{
    try
    {
        const response = await fetch(`${baseUrl}api/SimAssignment/assign/`,
        {
            method: 'POST', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                
                
               
            }), body: JSON.stringify(request)
        })
        
        if(!await response.ok ){

            return {"success":false, "data": response.statusText}
        }else{
            return {"success":true, "data": response.statusText}
        }
        
    } 
    catch(error)
    {
        return { "success": false, "data": error}
    }
}

export const CreatePortal = async(request, token , portalId) => {

    try{

        const response = await fetch(`${baseUrl}api/portal/create`,
        {
            method: 'POST', 
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "tokenPortalId": portalId
                
                
               
            }), body: JSON.stringify(request)
        })
        
        if(!await response.ok ){

            return {"success":false, "data": response.statusText}
        }else{
            return {"success":true, "data": response.statusText}
        }
        
    }catch(error){

        return {"success": false, "data": "Failed To Create Portal"}; 
    }
}

