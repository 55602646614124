import { Modal } from "react-bootstrap";

const CreateUserConfirmationPopup = (props) => {
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{props.setText} </span>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={props.backToUserAdmin}>
          Back to User Admin
        </button>
        <button className="btn btn-default" onClick={props.handleClose}>
          Create another user
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserConfirmationPopup;
