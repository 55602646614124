import React, { useState, Fragment, useContext, useCallback, useRef } from "react";
import config from "../../../../store/app.config";
import moment from "moment";
import * as XLSX from "xlsx";
import { Modal, Button, Spinner, ProgressBar } from "react-bootstrap";
import AuthContext from "../../../../store/auth-context";
import { HiDownload } from "react-icons/hi";
import { GetSelectedSimsRequest } from "../../../../services/SimService";

const SimExport = (props) => {
  const [simExportIsComplete, setSimExportIsComplete] = useState(false);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [loadingPageProgress, setLoadingPageProgress] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [progressBarProgress, setProgressBarProgress] = useState(0);
  const [exportFailed, setExportFailed] = useState(false);

  let simWording = props.selectedSimArray.length > 0 ? "selected" : "entire";

  // Variable
  const keepExportingRef = useRef(true);

  // Empty array to fill with transformed data
  let transformedData = [];

  // Gather data from API
  const getAllDataFromAPI = async (pageIndex, completeData) => {
    setIsLoading(true);
    setLoadingPageProgress(pageIndex);
    if (completeData === undefined) {
      completeData = [];
    }

    const response = await fetch(`${config.API_BASE_URL}api/sim/`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        pageNumber: pageIndex,
        pageSize: 10,
      }),
    });
    if (!response.ok) {
      setExportFailed(true);
    }
    const data = await response.json();
    setTotalPages(data.totalPages);
    data.data.forEach((e) => {
      transformedData.push({
        statusName: e.statusName,
        serialNumber: e.serialNumber,
        telNumber: e.telNumber,
        networkName: e.networkName,
        tariffName: e.tariffName,
        commencement: e.commencement !== null ? moment(e.commencement).format("Do MMM YYYY") : "",
        dataUsageMb: e.dataUsageMb !== null ? e.dataUsageMb.toFixed(2) : "",
        dataBundleMb: e.dataUsageMb !== null ? e.dataBundleMb.toFixed(2) : "",
        dataOverage:
          e.dataBundleMb === null || e.dataBundleMb === 0 ? "" : ((100 * e.dataUsageMb) / e.dataBundleMb).toFixed(2),
        billing: e.billing == null ? "" : e.billing.toFixed(2),
        textLabel: e.textLabel,
        value: "Bold",
        style: { font: { bold: true } },
      });
    });

    if (pageIndex < data.totalPages) {
      if (keepExportingRef.current === true) {
        getAllDataFromAPI(pageIndex + 1, completeData);
        setProgressBarProgress((100 * (pageIndex + 1)) / data.totalPages);
      } else {
        setProgressBarProgress(0);
      }
    } else {
      setExportData(transformedData);
      setIsLoading(false);
      setSimExportIsComplete(true);
      setProgressBarProgress(0);
      return completeData;
    }
  };

  const getSelectedDataFromApi = async () => {
    setIsLoading(true);
    let request = {
      serialNumbers: props.selectedSimArray,
    };

    const result = await GetSelectedSimsRequest(request, authCtx.token);

    if (result.error) {
      setExportFailed(true);
      setIsLoading(false);
    } else {
      result.data.forEach((e) => {
        transformedData.push({
          statusName: e.statusName,
          serialNumber: e.serialNumber,
          telNumber: e.telNumber,
          networkName: e.networkName,
          tariffName: e.tariffName,
          commencement: e.commencement !== null ? moment(e.commencement).format("Do MMM YYYY") : "",
          dataUsageMb: e.dataUsageMb !== null ? e.dataUsageMb.toFixed(2) : "",
          dataBundleMb: e.dataUsageMb !== null ? e.dataBundleMb.toFixed(2) : "",
          dataOverage:
            e.dataBundleMb === null || e.dataBundleMb === 0 ? "" : ((100 * e.dataUsageMb) / e.dataBundleMb).toFixed(2),
          billing: e.billing == null ? "" : e.billing.toFixed(2),
          textLabel: e.textLabel,
          value: "Bold",
          style: { font: { bold: true } },
        });
      });

      setIsLoading(false);
      setExportData(transformedData);
      setSimExportIsComplete(true);
      setProgressBarProgress(100);
    }
  };

  const checkExportType = () => {
    if (props.selectedSimArray.length > 0) {
      getSelectedDataFromApi();
    } else {
      exportDataHandler();
    }
  };

  const exportDataHandler = useCallback(async () => {
    keepExportingRef.current = true;
    getAllDataFromAPI(1);
  }, []);

  const cancelHandler = () => {
    keepExportingRef.current = false;
    setIsLoading(false);
    setSimExportIsComplete(false);
  };

  const handleCloseSimExport = () => {
    setSimExportIsComplete(false);
    setIsLoading(false);
    keepExportingRef.current = false;
    props.handleClose();
  };

  const formattedDate = new Date().toDateString().replace(/ /g, "-");

  const downloadSpreadSheet = async (exportData) => {
    const fileName = `${authCtx.decodedToken.portal_name.replace(/ /g, "-")}_${formattedDate}_SIM-list.xlsx`;
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    worksheet["!cols"] = [];
    if (authCtx.decodedToken.portal_tariffvisible !== "True") {
      worksheet["!cols"][4] = { hidden: true };
      worksheet["!cols"][7] = { hidden: true };
      worksheet["!cols"][8] = { hidden: true };
    }
    if (authCtx.decodedToken.portal_billingvisible !== "True") {
      worksheet["!cols"][9] = { hidden: true };
    }

    //This library apparently does not allow styling without a professional license, so need to hide columns containing styling information.
    worksheet["!cols"][11] = { hidden: true };
    worksheet["!cols"][12] = { hidden: true };
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, authCtx.decodedToken.portal_name);
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Modal show={props.show} centered>
      <Modal.Header>
        <Modal.Title>SIM Export</Modal.Title>
        <Button variant="modal-close" aria-label="Close" onClick={handleCloseSimExport}>
          <span aria-label="Close window">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p>Click below to prepare your {simWording} SIM list for export.</p>
        <p>Please note that this process may take several minutes, depending on the number of SIMs.</p>
      </Modal.Body>
      <Modal.Footer className="text-center pt-4 d-block">
        {isLoading && (
          <Fragment>
            <span>
              <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{" "}
              <strong>
                Exporting page {loadingPageProgress} of {totalPages}
              </strong>
            </span>
            <ProgressBar className="mt-2" now={progressBarProgress} />
          </Fragment>
        )}
        {isLoading && (
          <Button variant="link" size="sm" className="mx-auto d-block default" onClick={cancelHandler}>
            Cancel
          </Button>
        )}
        {!simExportIsComplete && !isLoading && (
          <Button variant="primary" onClick={checkExportType} className="mx-auto  my-2 d-block" size="sm">
            <span>Prepare download</span>
          </Button>
        )}
        {exportFailed && (
          <>
            <p className="text-failure">Export Failed!</p>
          </>
        )}
        {simExportIsComplete && (
          <Fragment>
            <Button variant="primary" onClick={() => downloadSpreadSheet(exportData)} className="mx-auto d-block">
              <HiDownload className="d-inline-block mr-2" /> <span>Download file (.xlsx)</span>
            </Button>
          </Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SimExport;
