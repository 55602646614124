import { useEffect, useState, useContext } from "react";
import { GetPortalRequests } from "../../services/SimService";
import RequestListTable from "./RequestListTable";
import AuthContext from "../../store/auth-context";
import "../UI/MainTable/MainTable.scss";

import { Spinner } from "react-bootstrap";
import Toolbar from "../SimList/Toolbar/Toolbar";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";

const RequestList = () => {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [portalRequests, setPortalRequests] = useState([]);
  const [pageSize] = useState(25);
  const [totalRecords, setTotalRecords] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  let portalName = authCtx.decodedToken.portal_name;

  useEffect(async () => {
    await GetRequestData(1, 20);
    setLoading(false);
  }, []);

  const refreshPage = async () => {
    setLoading(true);
    await GetRequestData(1, 20);
    setLoading(false);
  };

  const GetRequestData = async (pageNumber, pageSize) => {
    const request = {
      pageNumber,
      pageSize,
    };

    const portalRequestReturn = await GetPortalRequests(request, authCtx.token);

    if (await portalRequestReturn.data) {
      setCurrentPage(pageNumber);
      setTotalRecords(portalRequestReturn.total);
      setPortalRequests(portalRequestReturn.data);
    }
  };

  return (
    <>
      <Toolbar>
        <h2>{portalName + " - Requests"}</h2>
        <ToolbarButton
          action="refresh"
          label="Refresh"
          size="small"
          onClick={refreshPage}
        />
      </Toolbar>
      {loading && (
        <div className="d-flex justify-content-center m-5">
          <div className="loading">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />{" "}
            <span>Loading...</span>
          </div>
        </div>
      )}
      {!loading && (
        <RequestListTable
          data={portalRequests}
          updateData={GetRequestData}
          pageSize={pageSize}
          total={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default RequestList;
