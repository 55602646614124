import React, { useState } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);

  const [tokenExpired, setTokenExpired] = useState(false);

  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("decodedToken");
    localStorage.removeItem("token", token);
  };

  const timeoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token", token);
    localStorage.removeItem("decodedToken")
    setTokenExpired(true);
  };

  const loginHandler = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
    setTokenExpired(false);
  };

  let decodedToken = {
    portal_primarycolour: "0000ff",
    portal_primarytextcolour: "light",
    portal_secondarycolour: "ff00ff",
    portal_secondarytextcolour: "light",
    portal_buttoncolour: "00ffff",
    portal_buttontextcolour: "light",
    portal_menubartextcolour: "dark",
    portal_tariffvisible: false,
    portal_billingvisible: false,
  };

  if (token) {

    if(!localStorage.getItem("decodedToken")){
      decodedToken = jwt_decode(token);
      localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); 
    }else{
      decodedToken = JSON.parse(localStorage.getItem("decodedToken")); 
     
    }

    console.log(decodedToken);
    

    // Setting timeout handler (session expiry)
    let tokenExpiryDate = new Date(decodedToken.exp * 1000);

    const remainingTime = tokenExpiryDate - Date.now();

    if (tokenExpiryDate < Date.now()) {
      timeoutHandler();
    } else {
      setTimeout(timeoutHandler, remainingTime);
    }

    // Setting CSS variables based on company's chosen colours
    document.documentElement.style.setProperty(
      "--brand-primary",
      `#${decodedToken.portal_primarycolour}`
    );
    document.documentElement.style.setProperty(
      "--brand-secondary",
      `#${decodedToken.portal_secondarycolour}`
    );
    document.documentElement.style.setProperty(
      "--brand-button",
      `#${decodedToken.portal_buttoncolour}`
    );

    if (decodedToken.portal_primarytextcolour === "dark") {
      document.documentElement.style.setProperty(
        "--brand-primary-text",
        "#000"
      );
    } else {
      document.documentElement.style.setProperty(
        "--brand-primary-text",
        "#fff"
      );
    }

    if (decodedToken.portal_secondarytextcolour === "dark") {
      document.documentElement.style.setProperty(
        "--brand-secondary-text",
        "#000"
      );
    } else {
      document.documentElement.style.setProperty(
        "--brand-secondary-text",
        "#fff"
      );
    }

    if (decodedToken.portal_buttontextcolour === "dark") {
      document.documentElement.style.setProperty("--brand-button-text", "#000");
    } else {
      document.documentElement.style.setProperty("--brand-button-text", "#fff");
    }
    if (decodedToken.portal_menubarcolour === "light") {
      decodedToken.menuBarTheme = "light";
    } else {
      decodedToken.menuBarTheme = "dark";
    }
  }

  const contextValue = {
    token: token,
    decodedToken: decodedToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    tokenExpired: tokenExpired,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
