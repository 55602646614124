import { Fragment } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";

import ProfileForm from "./ProfileForm";

const UserProfile = () => {
  return (
    <Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <Card.Header className="custom-primary text-center">
                  <h2>Change Password</h2>
                </Card.Header>
                <Card.Body className="p-4">
                  <ProfileForm />
                  <div className="p-2 mt-4"></div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default UserProfile;
