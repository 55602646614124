import React, {
  useState,
  Fragment,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import config from "../../../../store/app.config";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import Table from "../../../UI/Table";
import AuthContext from "../../../../store/auth-context";
import { BsFillCheckCircleFill } from "react-icons/bs";

const SimSwap = (props) => {
  const [tableData, setTableData] = useState([]);
  const [simForSwap, setSimForSwap] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [simFound, setSimFound] = useState(null);
  const [isSimChecking, setIsSimChecking] = useState(false);
  const [isSimIdentical, setIsSimIdentical] = useState(false);
  const authCtx = useContext(AuthContext);
  const [simSwapIsComplete, setSimSwapIsComplete] = useState(false);
  const [simIsOnHire, setSimIsOnHire] = useState(false);

  const handleCloseSimSwap = () => {
    setSimSwapIsComplete(false);
    setIsLoading(false);
    setSimFound(null);
    setIsSimIdentical(false);
    setIsSimChecking(false);
    setSimForSwap("");
    props.handleClose();
  };

  // Validation of SIM serial
  const onBlurHandler = useCallback(async () => {
    setSimFound(null);
    setIsSimIdentical(false);
    setSimIsOnHire(false);
    if (simForSwap.length > 0) {
      setIsSimChecking(true);
      if (simForSwap === props.selectedSimArray[0]) {
        setIsSimIdentical(true);
        setIsSimChecking(false);
        return;
      }
      try {
        const response = await fetch(
          `${config.API_BASE_URL}api/sim/${simForSwap}`,
          {
            headers: new Headers({
              Authorization: `Bearer ${authCtx.token}`,
              "Content-Type": "application/json",
            }),
          }
        );
        setIsSimChecking(false);
        if (response.status !== 200) {
          setSimFound(false);
          throw new Error(simFound);
        } else {
          const data = await response.json();
          if (data.statusName === "On Hire") {
            setSimIsOnHire(true);
            throw new Error(
              "This SIM is already on hire and not available for swap"
            );
          }
          setSimFound(true);
        }
      } catch (error) {
        return error;
      }
    } else {
      setSimFound(null);
      setIsSimIdentical(false);
    }
  }, [authCtx, simForSwap, simFound, props.selectedSimArray]);

  // Receives selected SIMs, adds a serialnumber key so that it can be interpreted by the table
  useEffect(() => {
    const objectArray = [];
    if (props.selectedSimArray.length > 0) {
      props.selectedSimArray.forEach((element) =>
        objectArray.push({ serialNumber: element })
      );
      setTableData(objectArray);
    }
  }, [props.selectedSimArray]);

  const handleInput = (e) => {
    setSimFound(null);
    setIsSimIdentical(false);
    setSimForSwap(e.target.value);
  };

  const submitSimSwapHandler = useCallback(async () => {
    setIsLoading(true);
    setSimSwapIsComplete(true);
    setSimFound(null);

    const swapKey = props.selectedSimArray[0];

    try {
      const response = await fetch(`${config.API_BASE_URL}api/sim/swap`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${authCtx.token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          currentSerialNumber: swapKey,
          newSerialNumber: simForSwap,
        }),
      });
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data);
      }
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  }, [authCtx, props.selectedSimArray, simForSwap]);

  const columns = useMemo(
    () => [
      { Header: "Selected SIM for swap", accessor: "serialNumber", show: true },
    ],
    []
  );

  return (
    <Modal show={props.show} className="sim-swap" centered>
      <Modal.Header>
        <Modal.Title>SIM Swap</Modal.Title>
        <Button
          variant="modal-close"
          aria-label="Close"
          onClick={handleCloseSimSwap}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      {(!simSwapIsComplete && (
        <Fragment>
          <Modal.Body>
            <Container>
              <Table columns={columns} data={tableData} />
              <Row>
                <Col>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }} // preventDefault() stops form submission on Enter keystroke
                  >
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Enter serial number"
                        onChange={handleInput}
                        onBlur={onBlurHandler}
                        required
                        isValid={!isSimIdentical && !simIsOnHire && simFound}
                        isInvalid={
                          simIsOnHire || isSimIdentical || simFound === false
                        }
                      />
                      <Button
                        variant="light"
                        size="sm"
                        onClick={onBlurHandler}
                        aria-label="Validate SIM number"
                      >
                        {isSimChecking ? (
                          <Fragment>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                          </Fragment>
                        ) : (
                          "Validate"
                        )}
                      </Button>

                      <Form.Control.Feedback type="valid">
                        SIM serial found
                      </Form.Control.Feedback>
                      {isSimIdentical && (
                        <Form.Control.Feedback type="invalid">
                          SIM serial numbers must be different
                        </Form.Control.Feedback>
                      )}
                      {simFound === false && (
                        <Form.Control.Feedback type="invalid">
                          SIM serial does not exist
                        </Form.Control.Feedback>
                      )}
                      {simIsOnHire && (
                        <Form.Control.Feedback type="invalid">
                          This SIM is already on hire and not available for swap
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                    {isSimChecking && (
                      <Fragment>
                        <Form.Text className="text-muted">
                          Checking SIM serial...
                        </Form.Text>
                      </Fragment>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="default" onClick={handleCloseSimSwap}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={submitSimSwapHandler}
              disabled={!simFound}
            >
              Swap SIMs
            </Button>
            {isLoading && (
              <Button
                variant="primary"
                onClick={submitSimSwapHandler}
                disabled={true}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {""}
                Sending...
              </Button>
            )}
          </Modal.Footer>
        </Fragment>
      )) || (
        <Fragment>
          <Modal.Body className="text-center">
            <div className="error-success">
              <BsFillCheckCircleFill className="success-icon" size={35} />
            </div>
            <h5>SIM swap requested</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleCloseSimSwap}>
              Close
            </Button>
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  );
};

export default SimSwap;
