const local = {
  API_BASE_URL: "http://localhost:4000/",
};
const dev = {
  API_BASE_URL: "https://portal-api.dev.simusage.com/",
};
const live = {
  API_BASE_URL: "https://portal-api.simusage.com/",
};
function appConfiguration(settings, environmentName) {
  var self = this;
  this.isLocal = function () {
    return environmentName && environmentName.toLowerCase() === "local";
  };
  function init() {
    for (var setting in settings) self[setting] = settings[setting];
  }
  init();
}
const config = (() => {
  var env = process.env.REACT_APP_ENV;
  console.log('env: '+env);
  var environments = {
    local: local,
    dev: dev,
    live: live,
  };
  if (environments.hasOwnProperty(env))
    return new appConfiguration(environments[env], env);
  else return new appConfiguration(dev, "DEV");
})();
export default config;
