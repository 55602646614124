import { Fragment, useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner, Button, Stack } from "react-bootstrap";
import SimsAvailable from "./SimsAvailable";
import SimsOnPortal from "./SimsOnPortal";
import {
  GetAvailableSims,
  GetAvailableSimsForPortal,
  AssignSimsToPortal,
} from "../../../services/PortalService";
import { useParams, useLocation, useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { BiArrowBack } from "react-icons/bi";
import { IoMdArrowForward } from "react-icons/io";
import { BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import Toolbar from "../../SimList/Toolbar/Toolbar";
import ToolbarButton from "../../SimList/Toolbar/ToolbarButton";
import "./AddSims.scss";
import ConfirmationModal from "../../UI/ConfirmationModal";

function AddSimComponent(props) {
  const authCtx = useContext(AuthContext);
  let history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [availableSims, setAvailableSims] = useState([]);
  const [onPortalSims, setOnPortalSims] = useState([]);
  const [takenOffPortal, setTakenOffPortal] = useState([]);
  const [selectedAvailableSims, setSelectedAvailableSims] = useState([]);
  const [selectedOnPortalSims, setSelectedOnPortalSims] = useState([]);
  const [availableSimsTotal, setAvailableSimsTotal] = useState(0);
  const [onPortalSimsTotal, setOnPortalSimsTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [avialbeSimsCurrentPage, setAvailableSimsCurrentPage] = useState(1);
  const [onPortalCurrentPage, setOnPortalCurrentPage] = useState(1);
  let { portalId } = useParams();
  const [portalName, setPortalName] = useState(
    authCtx.decodedToken.portal_name
  );
  const [popUpText, setPopUpText] = useState("");
  const [show, setshow] = useState(false);

  const handleClose = () => {
    setshow(false);
    setPopUpText("");
  };

  useEffect(async () => {
    await GetInitialData();
    setPortalName(location.state.portalName);
    setLoading(false);
  }, []);

  const backToDashboard = () => {
    history.push({
      pathname: "/adminDashboard",
      state: {
        portalId: portalId,
        portalName: portalName,
      },
    });
  };

  const GetInitialData = async () => {
    const initialRequest = {
      portalId: "",
      pageNumber: 1,
      pageSize: pageSize,
      serialNumberFilter: "",
      portalToNotShow: portalId
    };
    initialRequest.portalId = authCtx.decodedToken.portal_id;
    const availableSimsReturn = await GetAvailableSims(
      initialRequest,
      authCtx.token
    );
    initialRequest.portalId = portalId;
    const portalSimsReturn = await GetAvailableSimsForPortal(
      initialRequest,
      authCtx.token
    );

    if ((await !availableSimsReturn.error) && (await !portalSimsReturn.error)) {
      let availableSimsOnly = availableSimsReturn.data.filter((value) => {
        return (
          portalSimsReturn.data.findIndex(
            (x) => x.serialNumber === value.serialNumber
          ) === -1
        );
      });
      setAvailableSimsTotal(availableSimsReturn.total);
      setOnPortalSimsTotal(portalSimsReturn.total);
      setAvailableSims(availableSimsOnly);
      setOnPortalSims(portalSimsReturn.data);
    } else {
      setHasError(true);
    }
  };

  const GetNewPortalData = async (searchTerm, pageNumber, pageSize) => {
    const request = {
      portalId: portalId,
      pageNumber: 1,
      pageSize: 20,
      serialNumberFilter: searchTerm,
    };

    const dataResponse = await GetAvailableSimsForPortal(
      request,
      authCtx.token
    );
    if (await !dataResponse.error) {
      setOnPortalSims(dataResponse.data);
    }
  };

  const GetNewAvailableSimsData = async (searchTerm, pageNumber, pageSize) => {
    const request = {
      portalId: authCtx.decodedToken.portal_id,
      pageNumber: pageNumber,
      pageSize: pageSize,
      serialNumberFilter: searchTerm,
      portalToNotShow: portalId
    };

    const dataResponse = await GetAvailableSims(
      request,
      authCtx.token
    );
    if (await !dataResponse.error) {
      setAvailableSims(dataResponse.data);
    }
  };

  const GetNewPortalSimsData = async (searchTerm, pageNumber, pageSize) => {
    const request = {
      portalId: portalId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      serialNumberFilter: searchTerm,
    };

    const dataResponse = await GetAvailableSimsForPortal(
      request,
      authCtx.token
    );
    if (await !dataResponse.error) {
      setOnPortalSims(dataResponse.data);
    }
  };

  const MoveSimsOntoPortal = () => {
    let toRemove = [];
    selectedAvailableSims.forEach((serialNumber) => {
      toRemove.push(serialNumber);
      setOnPortalSims((prevState) => [...prevState, serialNumber]);
    });

    let newAvailalbeSims = availableSims.filter((value) => {
      return (
        toRemove.findIndex((x) => x.serialNumber === value.serialNumber) == -1
      );
    });

    setAvailableSims(newAvailalbeSims);
  };

  const MoveSimsOffPortal = () => {
    let toRemove = [];
    selectedOnPortalSims.forEach((serialNumber) => {
      toRemove.push(serialNumber);
      setTakenOffPortal((prevState) => [...prevState, serialNumber]);
      setAvailableSims((prevState) => [...prevState, serialNumber]);
    });

    let newOnPortalSims = onPortalSims.filter((value) => {
      return (
        toRemove.findIndex((x) => x.serialNumber === value.serialNumber) == -1
      );
    });
    setOnPortalSims(newOnPortalSims);
  };

  const SaveChanges = async () => {
    setLoading(true);
    const onPortalSaved = await SaveOnPortal();
    const offPortalSaved = await SaveOffPortal();

    if ((await offPortalSaved) && onPortalSaved) {
      setPopUpText(
        <div className="error-success">
          <BsFillCheckCircleFill className="success-icon" size={35} />
          <h5>SIM list saved</h5>
        </div>
      );
    } else {
      setPopUpText(
        <div className="error-success">
          <BsFillXCircleFill className="failure-icon" size={35} />
          <h5>Error saving SIM list</h5>
        </div>
      );
    }
    setshow(true);
    setLoading(false);
  };

  const SaveOnPortal = async () => {
    try {
      let onPortalSerialNos = [];
      onPortalSims.forEach((element) => {
        onPortalSerialNos.push(element.serialNumber);
      });
      if (onPortalSerialNos.length == 0) return true;
      const request = {
        serialNumbers: onPortalSerialNos,
        portalId: portalId,
      };

      const response = await AssignSimsToPortal(request, authCtx.token);
      return response.success;
    } catch (error) {
      return false;
    }
  };

  const SaveOffPortal = async () => {
    try {
      let offPortalSerialNos = [];
      takenOffPortal.forEach((element) => {
        offPortalSerialNos.push(element.serialNumber);
      });
      if (offPortalSerialNos.length == 0) return true;

      const request = {
        serialNumbers: offPortalSerialNos,
        portalId: authCtx.decodedToken.portal_id,
      };

      const response = await AssignSimsToPortal(request, authCtx.token);
      return response.success;
    } catch (error) {
      return false;
    }
  };

  // Creates an array from the received list of selected SIMs consisting of only the serial numbers
  const [selectedAvailableSimArray, setAvailableSelectedSimArray] = useState(
    {}
  );
  const availableSimListUpdateHandler = (selectedRowIds) => {
    setAvailableSelectedSimArray(Object.keys(selectedRowIds));
  };

  // Creates the same array from the right-hand sub-component
  const [selectedIncludedSimArray, setIncludedSelectedSimArray] = useState({});
  const includedSimListUpdateHandler = (selectedRowIds) => {
    setIncludedSelectedSimArray(Object.keys(selectedRowIds));
  };

  return (
    <>
      <Toolbar>
        <h2>{portalName}</h2>
        <ToolbarButton
          label="Dashboard"
          onClick={backToDashboard}
          action="back"
        ></ToolbarButton>
      </Toolbar>
      {hasError && (
        <Fragment>
          <span>Error On Page ...Sorry </span>
        </Fragment>
      )}
      {loading && (
        <div className="loading">
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          <span>Loading...</span>
        </div>
      )}
      {!loading && (
        <>
          <Container className="overflow-hidden add-sims">
            <div className="text-center my-5">
              <h2 className="font-weight-semibold">Add or Remove SIMs</h2>
            </div>
            <Row className="mt-3 justify-content-md-center">
              <Col md="5" lg="auto">
                <SimsAvailable
                  data={availableSims}
                  setSelectedAvailableSims={setSelectedAvailableSims}
                  getNewData={GetNewAvailableSimsData}
                  pageSize={pageSize}
                  total={availableSimsTotal}
                  currentPage={avialbeSimsCurrentPage}
                  setCurrentPage={setAvailableSimsCurrentPage}
                  onAvailableSimListUpdate={availableSimListUpdateHandler}
                />
              </Col>
              <Col md="2" className="gx-5 py-4 d-flex align-items-center">
                <Stack
                  gap={3}
                  className="add-sim-controls justify-content-center align-items-center"
                >
                  <Button
                    variant="default"
                    disabled={selectedAvailableSimArray.length < 1}
                    onClick={MoveSimsOntoPortal}
                    size="lg"
                  >
                    <IoMdArrowForward />
                  </Button>
                  <Button
                    variant="default"
                    disabled={selectedIncludedSimArray.length < 1}
                    onClick={MoveSimsOffPortal}
                    size="lg"
                  >
                    <BiArrowBack />
                  </Button>
                  <Button variant="primary" onClick={SaveChanges}>
                    Save Changes
                  </Button>
                </Stack>
              </Col>
              <Col md="5" lg="auto">
                <SimsOnPortal
                  data={onPortalSims}
                  setSelectedOnPortalSims={setSelectedOnPortalSims}
                  getNewData={GetNewPortalSimsData}
                  pageSize={pageSize}
                  total={onPortalSimsTotal}
                  currentPage={onPortalCurrentPage}
                  setCurrentPage={setOnPortalCurrentPage}
                  onIncludedSimListUpdate={includedSimListUpdateHandler}
                />
              </Col>
            </Row>
          </Container>
          <ConfirmationModal
            show={show}
            handleClose={handleClose}
            text={popUpText}
            title={"SIM List Edit"}
          />
        </>
      )}
    </>
  );
}

export default AddSimComponent;
