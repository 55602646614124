import React, { useState, setState, useCallback, Fragment, useContext, useEffect, useRef } from "react";

import config from "../../../store/app.config";

import moment from "moment";
import AuthContext from "../../../store/auth-context";
import MainTable from "../../UI/MainTable/MainTable";
import { SimLabel } from "../../SimList/SimLabel/SimLabel";
import { Label } from "recharts";
import { render } from "@testing-library/react";

function SimControlTable(props) {
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const authCtx = useContext(AuthContext);
  const [searchTerm] = useState("");
  const [error] = useState(false);

  // Passing the request to clear selection to MainTable
  const clearSelection = useRef(null);
  const clearSelectionHandler = () => {
    clearSelection.current();
  };

  // Removing empty columns from decoded token using spread syntax to build up list which is passed via props.
  let hiddenCols = [];
  if (authCtx.decodedToken.portal_tariffvisible === "False") {
    hiddenCols = [...hiddenCols, "tariffName"];
  }
  if (authCtx.decodedToken.portal_tariffvisible === "False") {
    hiddenCols = [...hiddenCols, "dataBundleMb"];
  }
  if (authCtx.decodedToken.portal_tariffvisible === "False") {
    hiddenCols = [...hiddenCols, "dataOverage"];
  }
  if (authCtx.decodedToken.portal_billingvisible === "False") {
    hiddenCols = [...hiddenCols, "billing"];
  }

  // Defining columns for main table
  const columns = React.useMemo(
    () => [
      {
        Header: "SIM Status",
        accessor: "statusName",
        disableSortBy: true,
        Cell: ({ value }) => {
          const statusType = [
            "Connected",
            "Not yet connected",
            "Available for hire",
            "Awaiting RA",
            "Migrate-in Started (SIM Management)",
            "other",
          ];
          return statusType.includes(value) ? (
            <span
              className={value.replace(/\s/g, "")}
              //style={{ color: "#47b001" }}
            >
              {value}
            </span>
          ) : (
            value
          );
        },
      },
      { Header: "Serial No.", accessor: "serialNumber", disableSortBy: false },
      { Header: "Tel No.", accessor: "telNumber", disableSortBy: false },
      { Header: "Network", accessor: "networkName", disableSortBy: true },
      { Header: "Tariff", accessor: "tariffName", disableSortBy: true },
      {
        Header: "Live from",
        accessor: "commencement",
        disableSortBy: true,
        Cell: (cellInfo) => {
          return (
            <Fragment>
              {cellInfo.row.original.commencement !== null &&
                moment(cellInfo.row.original.commencement).format("Do MMM YYYY")}
            </Fragment>
          );
        },
      },
      {
        Header: "Data Usage",
        accessor: "dataUsageMb",
        disableSortBy: true,
        tipText: "Data usage may be up to 24 hours delayed",
        align: "right",
        Cell: (cellInfo) => {
          return (
            <Fragment>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                {cellInfo.row.original.dataUsageMb !== null
                  ? cellInfo.row.original.dataUsageMb >= 1024
                    ? (cellInfo.row.original.dataUsageMb / 1024).toFixed(2) + " GB"
                    : cellInfo.row.original.dataUsageMb.toFixed(1) + " MB"
                  : ""}
              </div>
            </Fragment>
          );
        },
      },
      {
        Header: "Bundle Size",
        accessor: "dataBundleMb",
        disableSortBy: true,
        align: "right",
        Cell: (cellInfo) => {
          return (
            <Fragment>
              <div
                style={{
                  textAlign: "right",
                }}
              >
                {cellInfo.row.original.dataBundleMb !== null
                  ? cellInfo.row.original.dataBundleMb >= 1024
                    ? (cellInfo.row.original.dataBundleMb / 1024).toFixed(2) + " GB"
                    : cellInfo.row.original.dataBundleMb.toFixed(1) + " MB"
                  : ""}
              </div>
            </Fragment>
          );
        },
      },
      {
        Header: "Data Overage",
        accessor: "dataOverage",
        disableSortBy: true,
        align: "right",
        Cell: (cellInfo) => {
          return (
            <div
              style={{
                textAlign: "right",
              }}
              className={
                cellInfo.row.original.dataUsageMb / cellInfo.row.original.dataBundleMb > 1 ? "text-danger" : ""
              }
            >
              {cellInfo.row.original.dataBundleMb === null || cellInfo.row.original.dataBundleMb === 0
                ? ""
                : cellInfo.row.original.dataUsageMb < cellInfo.row.original.dataBundleMb
                ? 0.0 + " MB"
                : cellInfo.row.original.dataUsageMb - cellInfo.row.original.dataBundleMb >= 1024
                ? ((cellInfo.row.original.dataUsageMb - cellInfo.row.original.dataBundleMb) / 1024).toFixed(2) + " GB"
                : (cellInfo.row.original.dataUsageMb - cellInfo.row.original.dataBundleMb).toFixed(2) + " MB"}
            </div>
          );
        },
      },
      {
        Header: "Unbilled Spend",
        accessor: "billing",
        disableSortBy: true,
        align: "right",
        Cell: (cellInfo) => {
          return (
            <Fragment>
              {cellInfo.row.original.billingCurrency &&
                cellInfo.row.original.billing !== null &&
                cellInfo.row.original.billing !== undefined &&
                cellInfo.row.original.billingCurrency}
              {cellInfo.row.original.billing == null ? "" : cellInfo.row.original.billing.toFixed(2)}
            </Fragment>
          );
        },
      },
      {
        Header: "Labels",
        accessor: "textLabel",
        disableSortBy: true,
        align: "left",
        Cell: (cellInfo) => {
          return (
            <SimLabel textLabel={cellInfo.row.original.textLabel} serialNumber={cellInfo.row.original.serialNumber} />
          );
        },
      },
    ],
    []
  );

  const fetchAPIData = async ({ pageSize, pageIndex, searchTerm, searchField, sortBy }) => {
    try {
      setLoading(true);
      var request = {
        pageNumber: pageIndex,
        pageSize: pageSize,
      };
      if (searchTerm !== undefined) {
        request.filters = {
          [searchField]: searchTerm,
        };
      }
      if (sortBy !== undefined) {
        if (sortBy[0] !== undefined) {
          request.sorting = {
            field: sortBy[0].id,
            ascending: !sortBy[0].desc,
          };
        }
      }
      const response = await fetch(`${config.API_BASE_URL}api/sim/`, {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${authCtx.token}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(request),
      });
      if (!response.ok) {
        throw error();
      }
      const data = await response.json();
      setData(data.data);
      setPageCount(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy, searchTerm, searchField }) => {
      fetchAPIData({
        pageSize: pageSize,
        pageIndex: pageIndex + 1,
        searchTerm: searchTerm,
        searchField: searchField,
        sortBy: sortBy,
      });
    },
    [searchTerm]
  );

  // Refresh function, passed through props
  useEffect(() => {
    props.refresh.current = fetchData;
    props.clearSelection.current = clearSelectionHandler;
  }, [props.clearSelection, props.refresh]);

  // Receive selected SIM list from Table, pass it up to SimControl
  const selectListUpdateHandler = (selectedRowIds) => {
    props.onSelectListUpdate(selectedRowIds);
  };

  // Receive page size from Table, pass it up to SimControl
  const pageSizeUpdateHandler = (pageSize) => {
    props.onPageSizeUpdate(pageSize);
  };

  return (
    <>
      {!error && (
        <MainTable
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
          onSelectListUpdate={selectListUpdateHandler}
          onPageSizeUpdate={pageSizeUpdateHandler}
          clearSelection={clearSelection}
          hiddenCols={hiddenCols}
          getCellProps={(cellInfo) => ({
            className: (cellInfo.value === "–" || cellInfo.value === null) && "grey",
          })}
          getRowId={(row) => row.serialNumber}
          enableSelection={true}
          enablePagination={true}
          enableSearch={true}
        />
      )}
      {error && <h2>Something went wrong... Sorry!</h2>}
    </>
  );
}

export default SimControlTable;
