import React, { Fragment } from "react";

import SimControl from "./SimControl/SimControl";

const SimList = (props) => {
  return (
    <Fragment>
      <SimControl />
    </Fragment>
  );
};

export default SimList;
