import { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import UserProfile from "./components/Profile/UserProfile";
import AuthPage from "./pages/AuthPage";
import DashboardPage from "./pages/DashboardPage";
import SimListPage from "./pages/SimListPage";
import ResetPassword from "./pages/ResetPassword";
import AuthContext from "./store/auth-context";
import AdminPage from "./pages/AdminPage";
import UserAdmin from "./components/UserAdmin/UserAdmin";
import EditUser from "./components/UserAdmin/EditUser";
import CreateUser from "./components/UserAdmin/CreateUser";
import PortalPage from "./components/PortalList/PortalPage";
import EditBrandingPage from "./components/EditBranding/EditBrandingPage";
import AdminDashboard from "./components/Admin/AdminDashboard";
import AddSimComponent from "./components/Admin/PortalAddSims/AddSimComponent";
import RequestListPage from "./pages/RequestListPage";
import SimAttributePage from "./pages/SimAttributePage";
import NewPasswordForm from "./components/Login/NewPasswordForm";

const App = () => {
  const authCtx = useContext(AuthContext);

  return (
    <Layout>
      {!authCtx.isLoggedIn && (
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>

          <Route path="/reset">
            <ResetPassword />
          </Route>
          <Route path="/resetPassword/:resetToken">
            <NewPasswordForm />
          </Route>

          <Route path="*">
            <Redirect to="/auth" />
          </Route>
        </Switch>
      )}

      {authCtx.isLoggedIn && (
        <Switch>
          <Route path="/dashboard">
            <DashboardPage />
          </Route>

          <Route path="/sims">
            <SimListPage />
          </Route>

          <Route path="/requests">
            <RequestListPage />
          </Route>
          <Route path="/profile">
            <UserProfile />
          </Route>

          <Route path="/admin">
            <AdminPage />
          </Route>

          <Route path="/userAdmin/:portalId">
            <UserAdmin />
          </Route>
          <Route path="/editUser/:id">
            <EditUser />
          </Route>
          <Route path="/newUser/:portalId">
            <CreateUser />
          </Route>
          <Route path="/portalList/:userId">
            <PortalPage />
          </Route>
          <Route path="/adminDashboard/">
            <AdminDashboard />
          </Route>
          <Route path="/editBranding">
            <EditBrandingPage />
          </Route>
          <Route path="/portalSims/:portalId">
            <AddSimComponent />
          </Route>
          <Route path="/sim/:serialNo">
            <SimAttributePage/>
          </Route>
          <Route path="/passwordReset">
            <ResetPassword/>
          </Route>
          <Route path="/resetPassword/:resetToken">
            <NewPasswordForm />
          </Route>
          <Route path="*">
            <Redirect to="/sims" />
          </Route>
        </Switch>
      )}
    </Layout>
  );
};

export default App;
