import { Container , Form, Row, Spinner} from "react-bootstrap";
import React, { useState, Fragment, useContext, useEffect } from "react";

const SimInfoBar = (props) => {

    return (
        <>
        <Row>
            <Form>
            <Form.Group>
                    <Form.Label>serial Number</Form.Label>
                    <Form.Control type="text" placeholder={props.simData.serialNumber} readonly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Tel Number</Form.Label>
                    <Form.Control type="text" placeholder={props.simData.telNumber} readonly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Network</Form.Label>
                    <Form.Control type="text" placeholder={props.simData.networkName} readonly/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Data Bundle (Mb)</Form.Label>
                    <Form.Control type="text" placeholder={props.simData.dataBundleMb} readonly/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Data Used (Mb)</Form.Label>
                    <Form.Control type="text" placeholder={props.simData.dataUsageMb} readonly/>
                </Form.Group>
            </Form>
        </Row>
        </>
    )
}

export default SimInfoBar; 