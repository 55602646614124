import { useState, useContext } from "react";
import { Spinner, Button } from "react-bootstrap";

import AuthContext from "../../store/auth-context";
import { HiUpload } from "react-icons/hi";

const ImageUploader = (props) => {
  const [badFile, setBadFile] = useState(false);
  const [uploading] = useState(false);
  const [fileMessage, setFileMessage] = useState("");
  const [tempSelectFile, setTempSelectFile] = useState();

  const ChangeHandler = (event) => {
    let file = event.target.files[0];
    if (file) {
      setBadFile(false);
      setTempSelectFile(file);
      props.updateSelectedFile(file);
    }
  };

  const handleUpload = () => {
    if (tempSelectFile) {
      setBadFile(false);
      setFileMessage("");
      let file = tempSelectFile;

      if (file.size > 1048576) {
        setFileMessage("File too big");
        setBadFile(true);
        props.setLogoFileOk(false);
        return;
      }
      if (file.type !== "image/png") {
        setFileMessage("File not correct format");
        setBadFile(true);
        props.setLogoFileOk(false);
        return;
      }
      props.setLogoFileOk(true);
      props.UpdateLogo(file);
    } else {
      setFileMessage("No file selected");
      setBadFile(true);
    }
  };

  return (
    <div className="image-upload-section">
      <input type="file" name="file" onChange={ChangeHandler} />
      {badFile && <span className="notification danger">{fileMessage}</span>}
      {fileMessage.length > 0 && !badFile && (
        <span className="notification success">{fileMessage}</span>
      )}
      <Button
        onClick={handleUpload}
        variant="secondary small"
        className="upload-button"
        disabled={!tempSelectFile || badFile}
      >
        {uploading ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <HiUpload />
        )}{" "}
        Upload image
      </Button>
      <div className="upload-note">
        <span>Image must not exceed 1.0MB</span>
        <span>Format must be png</span>
      </div>
    </div>
  );
};

export default ImageUploader;
