import React, { useState, Fragment, useContext, useEffect } from "react";

import { Spinner, Container } from "react-bootstrap";
import config from "../store/app.config";
import AuthContext from "../store/auth-context";

import DashboardTable from "../components/Dashboard/DashboardTable";

import "../components/Dashboard/Dashboard.scss";
import { Pie, PieChart, Cell, Legend } from "recharts";
import Toolbar from "../components/SimList/Toolbar/Toolbar";

const DashboardPage = () => {
  const [data, setData] = React.useState([]);
  const [overagePercentage, setOveragePercentage] = React.useState(0);
  const [overageData, setOverageData] = React.useState([]);
  const [overagePie, setOveragePie] = React.useState([
    { title: "a", value: 0, color: "#e38627" },
    { title: "b", value: 0, color: "#c13c37" },
  ]);
  const [spendcapPercentage, setSpendcapPercentage] = React.useState(0);
  const [spendcapData, setSpendcapData] = React.useState([]);
  const [spendcapPie, setSpendcapPie] = React.useState([
    { title: "a", value: 0, color: "#e38627" },
    { title: "b", value: 0, color: "#c13c37" },
  ]);
  const [loading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const [dashboardType, setDashboardType] = React.useState("overage");
  const [errorOccurred, setErrorOccurred] = React.useState(false);
  const fetchOverageData = async ({
    pageSize,
    pageIndex,
    searchTerm,
    searchField,
    sortBy,
  }) => {
    const response = await fetch(`${config.API_BASE_URL}api/sim/dashboard`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        pageNumber: 1,
        pageSize: 10,
        dashboardType: "overage",
        ResultTriggered: true,
      }),
    });
    if (!response.ok) {
      setErrorOccurred(true);
      console.log("Something went wrong!");
    } else {
      const data = await response.json();
      setOverageData(data.data);
      setOveragePercentage(data.percentage);
      setOveragePie([
        { name: "in overage", value: overagePercentage, color: "#c13c37" },
        {
          name: "out overage",
          value: 100 - overagePercentage,
          color: "#0088FE",
        },
      ]);
    }
  };

  const fetchSpendcapData = async ({
    pageSize,
    pageIndex,
    searchTerm,
    searchField,
    sortBy,
  }) => {
    const response = await fetch(`${config.API_BASE_URL}api/sim/dashboard`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${authCtx.token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        pageNumber: 1,
        pageSize: 10,
        dashboardType: dashboardType,
        ResultTriggered: true,
      }),
    });
    if (!response.ok) {
      setErrorOccurred(true);
      console.log("Something went wrong!");
    } else {
      const data = await response.json();
      setSpendcapData(data.data);
      setSpendcapPercentage(data.percentage);
      setSpendcapPie([
        { name: "over spendcap", value: spendcapPercentage, color: "#e38627" },
        {
          name: "under spendcap",
          value: 100 - spendcapPercentage,
          color: "#c13c37",
        },
      ]);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchSpendcapData({});
    await fetchOverageData({});
    setData(overageData);
    setLoading(false);
    setErrorOccurred(false);
  }, []);

  return (
    <div id="sim-control mb-5">
      <Toolbar>
        <h2>Dashboard</h2>
      </Toolbar>
      <Container className="my-5">
        {loading && !errorOccurred && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {errorOccurred && (
          <div className="d-flex justify-content-center">
            "An error occurred, please try refreshing the page."
          </div>
        )}
        <section className="pie-chart-section">
          <Fragment>
            {!loading && (
              <div className="pie-chart-container">
                <h4 class="text-center">Overage</h4>
                {
                  <PieChart width={300} height={200}>
                    <Legend verticalAlign="top" className="mb-3" />
                    <Pie
                      data={overagePie}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      dataKey="value"
                      fill="#0088FE"
                    >
                      {overagePie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                }
              </div>
            )}
            {!loading && (
              <div className="pie-chart-container">
                <h4 class="text-center">Spend Cap</h4>
                {
                  <PieChart width={300} height={200}>
                    <Legend verticalAlign="top" className="mb-3" />
                    <Pie
                      data={spendcapPie}
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      dataKey="value"
                      fill="#0088FE"
                    >
                      {overagePie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                  </PieChart>
                }
              </div>
            )}
          </Fragment>
        </section>

        <Fragment></Fragment>
      </Container>
      {!loading && (
        <section className="mx-3">
          <div className="tabs">
            <button
              action="spendcap"
              label="SIMs at Spend Cap"
              className={`tab${data === spendcapData ? " active" : ""}`}
              onClick={() => {
                setLoading(true);
                setDashboardType("spendcap");
                setData(spendcapData);
                setTimeout(function () {
                  setLoading(false);
                });
              }}
            >
              SIMs at Spend Cap
            </button>
            <button
              action="overage"
              label="SIMs in Overage"
              className={`tab${data === overageData ? " active" : ""}`}
              onClick={() => {
                setLoading(true);
                setDashboardType("overage");
                setData(overageData);
                setTimeout(function () {
                  setLoading(false);
                });
              }}
            >
              SIMs in Overage
            </button>
          </div>
          <DashboardTable
            title={dashboardType}
            data={data}
            pageSize={10}
            total={100}
            currentPage={1}
          />
        </section>
      )}
    </div>
  );
};

export default DashboardPage;
