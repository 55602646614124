import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { HiPencil } from "react-icons/hi";
import { ReactComponent as PreviewMenuLeft } from "../../images/branding-preview/preview-menu-left.svg";
import { ReactComponent as PreviewMenu } from "../../images/branding-preview/preview-menu.svg";
import ToolbarButton from "../SimList/Toolbar/ToolbarButton";

const BrandColourSelect = (props) => {
  const [menuBarDark, setMenuBarDark] = useState(true);
  const dark = "#222222";
  const light = "#f3f5f7";
  const menuBarColour = menuBarDark ? dark : light;
  const menuBarTextColour = !menuBarDark ? dark : light;
  const [primaryColourDisplay, setPrimaryColourDisplay] = useState("");
  const [primaryColourTextDark, setPrimaryColourTextDark] = useState(false);
  const primaryTextColour = primaryColourTextDark ? "#000000" : "#ffffff"; // Note that primary and secondary text colours use pure black or white
  const [secondaryColourDisplay, setSecondaryColourDisplay] = useState("");
  const [secondaryColourTextDark, setSecondaryColourTextDark] = useState(false);
  const secondaryTextColour = secondaryColourTextDark ? "#000000" : "#ffffff";
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.portal) {
      setMenuBarDark(!props.portal.menuBarColour);
      setPrimaryColourDisplay("#" + props.portal.primaryColour);
      setPrimaryColourTextDark(!props.portal.primaryTextColour);
      setSecondaryColourDisplay("#" + props.portal.secondaryColour);
      setSecondaryColourTextDark(!props.portal.secondaryTextColour);
    }

    setLoaded(true);
  }, []);

  const ChangeMenuDark = (event) => {
    if (event.currentTarget.value === "light") {
      setMenuBarDark(false);
      props.updateMenuBarDark(false);
    } else {
      setMenuBarDark(true);
      props.updateMenuBarDark(true);
    }
  };

  const ChangePrimaryColour = (event) => {
    let newColour = event.currentTarget.value;
    if (newColour) {
      setPrimaryColourDisplay(newColour);
      props.updatePrimaryColour(newColour.substr(1));
    }
  };

  const ChangePrimaryTextDark = (event) => {
    if (event.currentTarget.value === "light") {
      setPrimaryColourTextDark(false);
      props.updatePrimaryTextDark(false);
    } else {
      setPrimaryColourTextDark(true);
      props.updatePrimaryTextDark(true);
    }
  };

  const ChangeSecondaryColour = (event) => {
    let newColour = event.currentTarget.value;
    if (newColour) {
      setSecondaryColourDisplay(newColour);
      props.updateSecondaryColour(newColour.substr(1));
    }
  };

  const ChangeSecondaryTextDark = (event) => {
    if (event.currentTarget.value === "light") {
      setSecondaryColourTextDark(false);
      props.updateSecondarytextDark(false);
    } else {
      setSecondaryColourTextDark(true);
      props.updateSecondarytextDark(true);
    }
  };

  return (
    <>
      {loaded && (
        <div className="brand-selection">
          <Row>
            <Col sm={7} className="d-flex flex-column justify-content-center">
              <div className="preview-menu">
                <PreviewMenu className="d-none d-xl-block" />
                <PreviewMenuLeft className="d-xl-none" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={7}>
              <div
                style={{
                  backgroundColor: menuBarColour,
                  color: menuBarTextColour,
                }}
                className="menu-bar-preview"
              >
                {props.hasLogo && (
                  <>
                    <img
                      className="preview-logo"
                      src={`data:image/png;base64,${props.logoImageString}`}
                    ></img>
                  </>
                )}
              </div>
            </Col>
            <Col sm={5} className="d-flex flex-column justify-content-center">
              <div>
                <input
                  type="radio"
                  name="menubardark"
                  value="dark"
                  checked={menuBarDark}
                  onChange={ChangeMenuDark}
                />
                <label> Dark menu bar</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="menubardark"
                  value="light"
                  checked={!menuBarDark}
                  onChange={ChangeMenuDark}
                />
                <label> Light menu bar</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={7} className="d-flex flex-column justify-content-center">
              <div
                className="primary-preview toolbar"
                style={{
                  backgroundColor: primaryColourDisplay,
                }}
                textcolour={primaryColourTextDark ? "dark" : "light"}
              >
                <span
                  className="preview-title"
                  style={{ color: primaryTextColour }}
                >
                  {props.portalName}
                </span>
                <ToolbarButton label="Button" action="refresh" />
              </div>
            </Col>
            <Col sm={3} className="d-flex flex-column justify-content-center">
              <div className="edit-colour-group">
                <input
                  type="color"
                  name="primaryColourSelector"
                  value={primaryColourDisplay}
                  onChange={ChangePrimaryColour}
                />
                <label>
                  <HiPencil /> Edit Primary Colour
                </label>
              </div>
            </Col>
            <Col sm={2} className="d-flex flex-column justify-content-center">
              <div className="form-group">
                <input
                  type="radio"
                  name="primaryTextDark"
                  value="dark"
                  checked={primaryColourTextDark}
                  onChange={ChangePrimaryTextDark}
                />
                <label> Dark Text</label>
              </div>
              <div className="form-group">
                <input
                  type="radio"
                  name="primaryTextDark"
                  value="light"
                  checked={!primaryColourTextDark}
                  onChange={ChangePrimaryTextDark}
                />
                <label> Light Text</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={7} className="d-flex flex-column justify-content-center">
              <div className="preview-body">
                <div className="secondary-preview">
                  <div
                    className="header"
                    style={{
                      backgroundColor: secondaryColourDisplay,
                    }}
                  >
                    <span style={{ color: secondaryTextColour }}>Heading</span>
                  </div>
                  <div className="body">
                    <p>Previewing branding for {props.portalName}.</p>
                  </div>
                  <div className="footer">
                    <button
                      className="btn"
                      style={{
                        backgroundColor: primaryColourDisplay,
                        color: primaryTextColour,
                      }}
                    >
                      Button
                    </button>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: secondaryColourDisplay,
                        color: secondaryTextColour,
                      }}
                    >
                      Button
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              sm={3}
              className="d-flex flex-column justify-content-start mt-4"
            >
              <div className="edit-colour-group mt-2">
                <input
                  type="color"
                  name="secondaryColourSelector"
                  value={secondaryColourDisplay}
                  onChange={ChangeSecondaryColour}
                />
                <label>
                  <HiPencil /> Edit Secondary Colour
                </label>
              </div>
            </Col>
            <Col
              sm={2}
              className="d-flex flex-column justify-content-start mt-5"
            >
              <div>
                <input
                  type="radio"
                  name="secondaryTextDark"
                  value="dark"
                  checked={secondaryColourTextDark}
                  onChange={ChangeSecondaryTextDark}
                />
                <label> Dark Text</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="secondaryTextDark"
                  value="light"
                  checked={!secondaryColourTextDark}
                  onChange={ChangeSecondaryTextDark}
                />
                <label> Light Text</label>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default BrandColourSelect;
