import { useState, useContext } from "react";
import config from "../../store/app.config";
import { useHistory } from "react-router-dom";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import AuthContext from "../../store/auth-context";

//import { VscQuestion } from "react-icons/vsc";

const LoginForm = () => {
  const history = useHistory();

  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, { setSubmitting }) => {
        setIsLoading(true);

        fetch(`${config.API_BASE_URL}api/auth`, {
          method: "POST",
          body: JSON.stringify({
            EmailAddress: values.email,
            Password: values.password,
            returnSecureToken: true,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            setIsLoading(false);
            if (res.ok) {
              return res.text();
            } else {
              return res.text().then((data) => {
                throw new Error(errorMessage);
              });
            }
          })
          .then((data) => {
            authCtx.login(data);
            history.replace("/sims");
          })
          .catch((err) => {
            setErrorMessage(
              "Authentification failed. Please check your credentials and try again."
            );
          });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Email format is invalid.")
          .required("Enter your email address."),
        password: Yup.string()
          .required("Enter your password.")
          .min(8, "Password too short. Must be at least 8 characters long."),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div>
            <div className="text-center mt-2">
              <h2 className="text-primary">Welcome</h2>
              <p className="text-muted">Sign in to continue</p>
            </div>
            <Form onSubmit={handleSubmit}>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              {authCtx.tokenExpired && (
                <Alert variant="warning">Your session has expired.</Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                  tabIndex={1}
                />
              </Form.Group>

              {errors.email && touched.email && (
                <div className="input-feedback">{errors.email}</div>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                {/*                 <div className="float-end">
                  <Link to="/reset" variant="link" size="sm" tabIndex={4}>
                    <VscQuestion /> Forgot password?
                  </Link>
              </div> */}
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.password && touched.password && "error"}
                  tabIndex={2}
                />
              </Form.Group>

              {errors.password && touched.password && (
                <div className="input-feedback">{errors.password}</div>
              )}
              <div className="d-grid gap-2">
                {!isLoading && (
                  <Button variant="primary" type="submit" tabIndex={3}>
                    Sign in
                  </Button>
                )}
                {isLoading && (
                  <Button variant="primary" disabled tabIndex={3}>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Sending request...
                  </Button>
                )}
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
