import { Container , Spinner, Row} from "react-bootstrap";
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {GetSimData } from '../services/SimService'
import Toolbar from "../components/SimList/Toolbar/Toolbar";
import AuthContext from "../store/auth-context";
import SimInfoBar from "../components/SimAttribute/SimInfoBar"

const SimAttributePage = () =>{
    const authCtx = useContext(AuthContext);
    const [loading, setLoading] = useState(true); 
    const [errorOccurred, setErrorOccurred] = useState(false); 
    const [simData, setSimData] = useState({}); 
    let { serialNo } = useParams();

    useEffect( async()=>{

      let simData = await GetSimData(serialNo, authCtx.token)
      setSimData(simData); 

      setLoading(false); 

    }, [serialNo])
 return (
     <div>
        <Toolbar>
        <h2>Sim Attribute</h2>
      </Toolbar>
      <Container>
        {loading && !errorOccurred && (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        {!loading && !errorOccurred && (
          <>
          <Row>
            <SimInfoBar 
            simData = {simData}
            />
          </Row>
          </>
        )}
          
        
      </Container>
     </div>
 )

}

export default SimAttributePage; 