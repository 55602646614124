// import Button from "./UI/Button";
import { useContext } from "react";
import AuthContext from "../store/auth-context";
import { Button, Modal } from "react-bootstrap";

const Terms = (props) => {
  const authCtx = useContext(AuthContext);
  return (
    <Modal show={props.show} size="lg">
      <Modal.Header>
        <Modal.Title>Terms of Use</Modal.Title>
        <Button
          variant="modal-close"
          aria-label="Close"
          onClick={props.handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <h3>
          "{authCtx.decodedToken.portal_name}" - General conditions of offers on
          the SIM Usage Portal
        </h3>
        <p>
          The Company: Company providing mobile telecom solutions, "
          {authCtx.decodedToken.portal_name}"
        </p>
        <p>
          The Customer: The company using the portal and SIM cards provided by
          The Company to provide telecoms solutions, to its staff or onwards to
          another company.
        </p>
        <p>
          SIM Cards: Any SIM card associated with an internet package, provided
          by The Company and subject to a commercial contract between The
          Company and The Customer.
        </p>
        <p>
          The Portal: The simusage.com portal from The Company allowing The
          Customer the ability to view, manage and control SIM Cards such that
          The Customer can self-serve aspects of the SIM behaviour and
          operation.
        </p>
        <h4>ARTICLE 1 - PURPOSE</h4>
        <p>
          1.1 This contract defines the terms and conditions for the usage of
          The Portal as it relates to the contract for the supply SIM Cards from
          The Company to The Customer.
        </p>
        <p>
          1.2 The Company reserves the right to carry out a Company Credit Check
          before the creation of access to the portal. Unless notified within 24
          hours of acceptance, these general terms and conditions apply
          automatically to all packages offered through The Portal to The
          Customer.
        </p>
        <h4>ARTICLE 2 - DURATION</h4>
        <p>
          2.1. The terms and conditions for use of the portal come into force at
          the point access is granted by The Company to one or more users of The
          Customer.
        </p>
        <p>
          2.2 The Customer is responsible for any fees and payment, according to
          their contract with The Company, from actions taken on the portal that
          incur charges for SIM Cards supplied to The Customer and controlled
          from within The Portal.
        </p>
        <p>
          2.3 The end date of the contract will be at a date agreed between The
          Company and The Customer when The Portal is no longer required to
          manage SIM Cards. This may be because there is no longer a contract
          between The Company and The Customer for supply of SIM Cards, or
          access for The Portal has been closed down by The Company.
        </p>
        <p>
          2.4 The Company reserve the right to remove access to The Portal at
          any stage if it feels that The Customer is misusing the portal or the
          SIM Cards in a way that contravenes the terms of these terms or the
          commercial contract between The Company and The Customer.
        </p>
        <h4>ARTICLE 3 - USE SIM CARDS AND GUARANTEE</h4>
        <p>
          3.1 The Company undertakes to make available to the Client, SIM Cards
          in good operational condition. The Customer undertakes to inform The
          Company of any apparent defect discovered, or any activation defect
          within 48 hours of that activation.
        </p>
        <p>
          3.2 The Customer undertakes to use the SIM Cards in accordance with
          the technical advice provided, namely: installation in any equipment
          whether provided by The Company or The Customer&rsquo;s own, and
          environment specifications of the equipment (characteristics of the
          power network, transmission, temperature ...), compliance with the
          specifications of use.
        </p>
        <p>
          3.3 The Customer is prohibited from associating the SIM Cards made
          available with technical solutions for the re-routing of
          communications by any technical process. In such a case, The Company
          reserves the right to suspend and then terminate the contract.
        </p>
        <p>
          3.4 The Portal can only provide information as it becomes available
          from the telecoms networks ultimately providing the solutions. There
          is typically a lag in such information and therefore details of usage
          and billing shown on The Portal should be considered illustrative and
          may be subject to future revision.
        </p>
        <p>
          3.5 SIM Card actions taken within The Portal are not necessarily
          real-time and there may be a delay between an action in The Portal and
          that action taking effect on the SIM card. It can sometimes be
          possible for an action to be rejected by a network depending on
          circumstance. Therefore, the request for an action cannot be taken as
          a guarantee that this action will certainly take place.
        </p>
        <p>
          3.6 The Portal is provided on a best-efforts basis and, from time to
          time, it may be necessary to take The Portal down for a short period
          of maintenance or to throttle the responsiveness of The Portal when
          under heavy load. The target availability of the portal is 95%.
        </p>
        <p>
          3.7 The Portal should only be used by a human operator. It is not
          permitted for The Company, The Customer or Third Parties to create
          automation tools that attempt to use features of The Portal in an
          automated fashion. Such use could result in the removal of access
          rights and the termination of this contract.
        </p>
        <h4>ARTICLE 4 - TRANSFER OF ANY MATERIAL SUPPLIED</h4>
        <p>
          In the event that the customer requires to transfer the SIM Cards
          and/or any equipment supplied by The Company, in accordance with the
          terms of the overall agreement, this cannot be done without first
          consulting and obtaining agreement from The Company. The Company may
          transfer or assign to third parties, in whole or in part, its rights
          under this contract and any other connected contract, as well as any
          equipment supplied by The Company.
        </p>
        <h4>ARTICLE 5 - TERMS OF PAYMENT</h4>
        <p>
          5.1 The Customer is liable for any fees due from the usage of SIM
          Cards provided by The Company and/or actions taken on these SIM cards
          through The Portal. These fees will be according to the commercial
          contract between The Customer and The Company and apply until the
          manual or automatic deactivation of the contract.
        </p>
        <p>
          5.2 The invoices issued by The Company following the activation of the
          SIM Cards may include Line Rental and Usage (calls, SMS, data)
          incurred through the usage of the SIM Card.
        </p>
        <p>
          5.3 Disputes over the fees charged must be raised in accordance with
          the commercial contract between The Company and The Customer,
          otherwise, they will be considered correct, and no further claims will
          be accepted.
        </p>
        <p>
          5.4 Any charges, communication costs and any amount due to The Company
          under the Contract are payable within the period defined by the
          commercial contract between The Company and The Customer. Any
          penalties, interest payments or late payments are detailed in this
          contract.
        </p>
        <p>
          5.5 Taxes and fees will be those in effect at the time of invoicing.
          They will be borne by The Customer who must pay them.
        </p>
        <h4>ARTICLE 6 - LIABILITY</h4>
        <p>
          6.1 The Company undertakes that the SIM Cards will be in working order
          upon activation but will not be held responsible for the operation of
          the telephone network to which it is connected.
        </p>
        <p>
          6.2 The Customer is also responsible for checking any special costs
          they may use (in voice and data) with The Company, especially if these
          are not indicated on the commercial offer (e.g. premium rate SMS). The
          Company will transmit all information on a use deemed abnormal as soon
          as it becomes aware of it.
        </p>
        <p>
          6.3 Subject to the provisions of public order, The Company cannot be
          held responsible for any claims, losses or any direct, indirect, or
          consequential damage (such as loss of operation, turnover,
          Customer...) caused by the SIM Cards supplied by The Company, or by
          its malfunction or non-functioning.
        </p>
        <p>
          6.4 In case of Loss or Theft, The Customer is obliged to deactivate
          the SIM Card immediately through The Portal. The Customer is
          responsible for the use of the SIM card and the payment of all
          communications made before decommissioning, without exception, even if
          communications are invoiced later because of delays by the providing
          Network.
        </p>
        <p>
          6.5 The Company disclaims any responsibility in the event of
          non-compliance with legislation in force in the context of all uses
          made by the subscriber of the equipment made available.
        </p>
        <h4>ARTICLE 7 - GENERAL PROVISIONS</h4>
        <p>
          7.1 This Agreement constitutes the entire agreement between the
          parties regarding the use of an The Portal as it relates to the
          provision of telecoms services, replacing any written and/or oral
          proposals and any other communications between the parties relating to
          the content of these terms and conditions.
        </p>
        <p>
          7.2 The fact that one of the parties does not rely on a breach by the
          other party of any of the obligations of this contract shall not be
          interpreted for the future as a waiver of the performance of the
          obligation in question.
        </p>
        <p>
          7.3 For the purposes of this contract both parties agree to elect to
          use the registered address as the main address.
        </p>
        <h4>ARTICLE 8 - APPLICABLE LAW</h4>
        <p>
          This Agreement (and any associated non-contractual claims or disputes)
          shall be governed by and construed in accordance with the laws of
          England and Wales, and each party agrees to submit to the exclusive
          jurisdiction of the English Courts.
        </p>
        <h4>ARTICLE 9 - COMPETENT JURISDICTION</h4>
        <p>
          9.1 Any dispute arising from the interpretation, execution and/or
          termination of this contract will be subject to the jurisdiction Laws
          of England and Wales
        </p>
        <p>
          9.2 Any disputes should initially be referred to the account manager
          or The Company&rsquo;s Customer Service team
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Terms;
