import { useTable } from "react-table/dist/react-table.development";
import { Fragment, useMemo } from "react";
import moment from "moment";
import "../UI/MainTable/MainTable.scss";
import { Table, Container } from "react-bootstrap";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const RequestListTable = ({
  data,
  updateData,
  pageSize,
  total,
  currentPage,
  setCurrentPage,
}) => {
  const columns = useMemo(
    () => [
      { Header: "User", accessor: "userName", disableSortBy: true },
      {
        Header: "Created",
        accessor: "createDate",
        disableSortBy: true,
        Cell: (cellInfo) => {
          return (
            <Fragment>
              {cellInfo.row.original.createDate !== null &&
                moment(cellInfo.row.original.createDate).format("Do MMM YYYY")}
            </Fragment>
          );
        },
      },
      { Header: "Command", accessor: "command", disableSortBy: true },
      { Header: "Details", accessor: "details", disableSortBy: true },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value }) => {
          const statusType = ["Success", "Failure"];
          return statusType.includes(value) ? (
            <span className={value.replace(/\s/g, "")}>{value}</span>
          ) : (
            value
          );
        },
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(currentPage - 1);
      updateData(newPage, pageSize);
    }
  };

  const nextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(currentPage + 1);
    updateData(newPage, pageSize);
  };

  return (
    <Fragment>
      <Container>
        <div className="table-group fancy-table mt-4">
          <div className="table-container">
            <Table striped bordered hover {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {total > 0 && (
            <div className="pagination">
              <button onClick={previousPage} disabled={currentPage < 2}>
                <HiChevronLeft className="icon" />
              </button>
              <button className="current">{currentPage}</button>

              <button
                onClick={nextPage}
                disabled={currentPage * pageSize > total}
              >
                <HiChevronRight className="icon" />
              </button>
            </div>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default RequestListTable;
