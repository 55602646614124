import { Fragment } from "react";
import { Modal } from "react-bootstrap";

const DeleteConfirmationPopup = (props) => {
  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.setText}</Modal.Body>
      <Modal.Footer>
        {!props.deletionSuccessful ? (
          <>
            <button className="btn btn-primary" onClick={props.handleOk}>
              Delete user
            </button>
            <button className="btn btn-default" onClick={props.handleClose}>
              Cancel
            </button>
          </>
        ) : (
          <button className="btn btn-default" onClick={props.handleClose}>
            Close
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationPopup;
